let NODE_ENV = 'production';


let Front_URL = "";
let Server_URL = "";

let baseUrl = "";
let BUSDAddress = "";
let ZolttanAddress = "";

let BNBSYMBOL = "";
let toFixedSwap = "";
let toFixedBal = "";
let NetworkId = "";
let toFixed = "";
let networkName = "";
let toFixedvalue = "";
let txUrlAddress = "";
let txurl = "";
let tokenSymbol = "";
let AdminAddress = "";
let ZolttanTokenAddress = "";
let projectId = "";
let rpcUrl = "";


if (NODE_ENV == "production") {
    Front_URL = 'https://www.zolttan.com/';
    Server_URL = 'https://producationapi.zolttan.com/';
    baseUrl = 'https://producationapi.zolttan.com'
    AdminAddress = "0x373f886904048975034488320e37d171e092c135";
    BUSDAddress = "";
    ZolttanAddress = "0x611a77a4Acfe256e968Bf4A13f8d95a4ae493FEE";
    ZolttanTokenAddress = "";
    txUrlAddress = "https://bscscan.com/address/";
    txurl = "https://bscscan.com/tx/";
    rpcUrl = "https://bsc-dataseed.binance.org/";
    BNBSYMBOL = 'BNB';
    toFixedSwap = 18;
    toFixedvalue = 8;
    NetworkId = 56;
    toFixed = 8;
    toFixedBal = 18;
    networkName = "Binance Chain";
    tokenSymbol = "Zolttan"
    projectId   = "cb89ebb21cdccb2e1b591e189e27706a";//wallet connect
}
else if (NODE_ENV === "demo") {
    Front_URL = 'https://zolttan-frontend.pages.dev/';
    Server_URL = 'https://zolttanapi.wearedev.team/';
    baseUrl = 'https://zolttanapi.wearedev.team'
    AdminAddress = "0x373f886904048975034488320e37d171e092c135";
    BUSDAddress = "0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F";
    ZolttanAddress = "0x5Cf4a9B487763b786D2D76246f1634862F792472";
    ZolttanTokenAddress = "0x006C4d9Ae59EDd1c7Dcc8a41d86B8a46881f1421";
    txUrlAddress = "https://testnet.bscscan.com/address/";
    txurl = "https://testnet.bscscan.com/tx/";
    rpcUrl = "https://data-seed-prebsc-1-s3.binance.org:8545/";
    BNBSYMBOL = 'BNB';
    toFixedSwap = 18;
    toFixedvalue = 8;
    NetworkId = 97; //56--97
    toFixed = 8;
    toFixedBal = 18;
    networkName = "Binance Chain";
    tokenSymbol = "Zolttan"
    projectId   = "cb89ebb21cdccb2e1b591e189e27706a";//wallet connect
}

else {
    Front_URL = 'http://localhost:3000/';
    Server_URL = 'http://localhost:4001/';
    baseUrl = 'http://localhost:4001'
    AdminAddress = "0xC063C6620B0494A833f4bC5919327Ba1B2831B12";
    BUSDAddress = "0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F";
    ZolttanAddress = "0x6AE2B01e4B6672538604D2bA9Aba7a120f694F24"
    ZolttanTokenAddress = "0x006C4d9Ae59EDd1c7Dcc8a41d86B8a46881f1421";
    txUrlAddress = "https://testnet.bscscan.com/address/";
    txurl = "https://testnet.bscscan.com/tx/";
    BNBSYMBOL = 'BNB';
    toFixedSwap = 18;
    toFixedvalue = 8;
    NetworkId = 97;
    toFixed = 8;
    toFixedBal = 18;
    networkName = "Binance Chain";
    tokenSymbol = "Zolttan"
    projectId   = "cb89ebb21cdccb2e1b591e189e27706a";//wallet connect
}

let key = {
    Front_URL: Front_URL,
    Server_URL: Server_URL,
    baseUrl: baseUrl,
    AdminAddress: AdminAddress,
    BUSDAddress: BUSDAddress,
    ZolttanAddress: ZolttanAddress,
    BNBSYMBOL: BNBSYMBOL,
    toFixedSwap: toFixedSwap,
    toFixedBal: toFixedBal,
    toFixedvalue: toFixedvalue,
    NetworkId: NetworkId,
    toFixed: toFixed,
    networkName: networkName,
    txUrlAddress: txUrlAddress,
    txurl: txurl,
    rpcUrl:rpcUrl,
    tokenSymbol: tokenSymbol,
    ZolttanTokenAddress: ZolttanTokenAddress,
    projectId:projectId
};

export default key;
