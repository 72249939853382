import React, { useState, useEffect , useRef } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import Web3Modal from 'web3modal'
import { connection } from "../helper/connection";
import { setWallet } from "../reducers/Actions";
import { toFixedFormat } from "../helper/customMath";
import config from '../config/config';
import { providerOptions } from "../config/providerOptions";
import { type WalletClient, useWalletClient,useDisconnect } from 'wagmi'
import { useConnect } from 'wagmi';
import { toastAlert } from "../helper/toastAlert";
import { BrowserProvider, JsonRpcSigner } from 'ethers'
import { useSelector, useDispatch } from "react-redux";
import Web3 from "web3";
import ZolttanAbi from "../ABI/ZolttanABI";
import logos from "../assets/images/logo.png"

export default function Navbar(props) {
  const timerRef = useRef(null);
  const { connect, connectors, error, isLoading, pendingConnector } =useConnect();
  const { disconnect } = useDisconnect()
  let chainid = config.NetworkId ;
  const { data: walletClient } = useWalletClient({ chainid })
  const dispatch = useDispatch()
  const [data, setdata] = useState({});
  const [selected, setSelected] = useState("GB");
  const [balance, setbalance] = useState("")
  const [useraddress, setuseraddress] = useState("");
  const [userExist, setUserExist] = useState(false);
  const walletConnection = useSelector((state) => state.walletConnection)
  useEffect(() => {
    loadScript();
  }, []);

  function loadScript() {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 100) {
        $(".main_navbar").addClass("fixed_navbar");
      } else {
        $(".main_navbar").removeClass("fixed_navbar");
      }
    });
  }
  useEffect(() => {
    checkConnect();
  }, [walletClient])

  async function logout() {
    localStorage.clear();
    disconnect();
    setTimeout(function(){
      window.location.href = "/";
     },700)
  }

  async function checkConnect(){
    if(walletClient){
      const { account, chain, transport } = walletClient
        const network = {
            chainId: chain.id,
            name: chain.name,
            ensAddress: chain.contracts?.ensRegistry?.address,
        }
        const provider = new BrowserProvider(transport, network)
        const signer = new JsonRpcSigner(provider, account.address)
         var web3  =new Web3(transport) 
        var result = await web3.eth.getAccounts();
        var networks = await web3.eth.net.getId();
        let bnbbalance = await web3.eth.getBalance(result[0]);
        if(networks==config.NetworkId){
        var bal = bnbbalance / 10 ** 18;
        bal = await toFixedFormat(bal);
        setbalance(bal);
        setuseraddress(result[0]);
        dispatch(setWallet({
          network: chain.id,
          web3: transport,
          address: result[0],
          provider: transport,
          connect: "yes",
          userdetails: walletConnection.userdetails
        }));
        getuserBalance(transport,result[0]);
      }else{
       dispatch(setWallet({
          network: "",
          web3: "",
          address: "",
          provider: "",
          connect: "no",
          userdetails: ""
        }));
        toastAlert('error', "please select BSC chain network", 'network');
      }
    }
  }

  async function getuserBalance(t_web3,currAddr) {
    if (t_web3) {
      var web3  =new Web3(t_web3) 
      let deficontract = new web3.eth.Contract(ZolttanAbi,config.ZolttanAddress)
      let userdetails = await deficontract.methods.users(currAddr).call();
      var isExist = userdetails && userdetails.isExist ? userdetails.isExist : false
      setUserExist(isExist)
    }
  }

  window.addEventListener('load', async (event) => {
   // if (localStorage.getItem("account")) {
      event.preventDefault();
      var provider = window.ethereum;
      var web3 = new Web3(provider);
      if (window.ethereum) {
        window.ethereum.on('accountsChanged', async function (accounts) {
          if (timerRef.current) {
            clearTimeout(timerRef.current);
          }
          timerRef.current = setTimeout(async () => {
            var result = await web3.eth.getAccounts()
            var setacc = result[0];
            localStorage.setItem("account", result[0])
            window.location.href="/";
          }, 1000);
        })

        window.ethereum.on('networkChanged', async function (networkId) {
          var provider = window.ethereum;
          var web3 = new Web3(provider);
          if (networkId == config.NetworkId) {
            if (timerRef.current) {
              clearTimeout(timerRef.current);
            }
            timerRef.current = setTimeout(async () => {
              var result = await web3.eth.getAccounts()
              var setacc = result[0];
              localStorage.setItem("account", result[0])
              window.location.href="/";
            }, 1000);
          }
          else {
            localStorage.clear()
            window.location.href="/";
          }
        })
      }
    //}
  })

  async function connectAccount(connector){
    try{
      localStorage.removeItem('referaladdress');
      let rec =   connect(connector);
      window.$('#connect_wallet_modal').modal('hide');
    }catch(err){
      console.log(err,"====+++errrr")
    }
  }

  return (
    <>
      <nav className="navbar navbar-dark navbar-expand-lg main_navbar">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img
              src={require("../assets/images/logo.png")}
              className="img-fluid brand_logo"
              alt="logo"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarScroll">
            <div className="ms-auto navbar_right">
              {/* After Login Menus */}
              {
                walletConnection && walletConnection.address != '' && walletConnection.address != null && walletConnection.address != undefined && userExist == true ?
                  <>

                    <div className="dropdown me-3">
                      <a
                        className="primary_btn dropdown-toggle address_btn"
                        href="#"
                        role="button"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {walletConnection.address}
                      </a>
                      <ul
                        className="dropdown-menu walletdrop "
                        aria-labelledby="dropdownMenuLink"
                      >
                        <li>
                          <a className="dropdown-item" href="#">
                            <label>BNB Balance:</label>
                            <span className="ms-2">{balance && parseFloat(balance) > 0 ? parseFloat(balance) : 0}</span>
                          </a>
                        </li>
                        <li>
                          <Link to={'/dashboard'} className="dropdown-item">Dashboard</Link>
                        </li>
                      </ul>
                    </div>

                    <button className="primary_btn" onClick={() => logout()}>Logout</button>
                  </>


                  : walletConnection && walletConnection.address != '' && walletConnection.address != null && walletConnection.address != undefined && userExist == false ?

                    <>
                      <div className="dropdown me-3">
                        <a
                          className="primary_btn dropdown-toggle address_btn"
                          href="#"
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {walletConnection.address}
                        </a>
                        <ul
                          className="dropdown-menu walletdrop "
                          aria-labelledby="dropdownMenuLink"
                        >
                          <li>
                            <Link to={'/join-now'} className="dropdown-item">Join now</Link>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <label>BNB Balance:</label>
                              <span className="ms-2">{balance && parseFloat(balance) > 0 ? parseFloat(balance) : 0}</span>
                            </a>
                          </li>
                          {
                            userExist == true ?
                              <li>
                                {/* <Link to={'/dashboard'} className="dropdown-item">Dashboard</Link> */}
                              </li> : ""
                          }

                        </ul>
                      </div>
                      <div className="dropdown me-3">
                        <Link to={'/join-now'} className="primary_btn">Join now</Link>
                      </div>

                      <button className="primary_btn" onClick={() => logout()}>Logout</button>
                    </>
                    : walletConnection && walletConnection.address == '' &&
                    <button
                      className="primary_btn"
                      data-bs-toggle="modal"
                      data-bs-target="#connect_wallet_modal"
                    >
                      Connect Wallet
                    </button> 
              }


            </div>
          </div>
        </div>
      </nav>

      {/* Connect Wallet Modal */}
      <div
        className="modal fade primary_modal"
        id="connect_wallet_modal"
        tabIndex={-1}
        aria-labelledby="connect_walletModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_walletModalLabel">
                Connect Wallet
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <div className="m-2 mb-3">
                  {connectors && connectors.length>0 && connectors.map((connector,index)=>{
                var logo  = "metamask.png";
                var name = "MetaMask";
                if (connector.name === "MetaMask") {
                    logo = "metamask.png";
                    name = "MetaMask";
                } else if (connector.name === "WalletConnect") {
                    logo = "walletconnect.png";
                    name = "Wallet Connect";
                }
                return(
                <a target="_blank" className="mb-3 d-block" onClick={()=>connectAccount({connector})}>
                  <button type="button" className="connect_wallet_button">
                    <img src={require(`../assets/images/${logo}`)} height="28" width="28" className="img-fluid" /> <span>{name}</span>
                  </button>
                </a>
                )
              })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </>
  );
}
