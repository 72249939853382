import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { AnimatedTree } from "react-tree-graph";
import "react-tree-graph/dist/style.css";
import { toastAlert } from "../helper/toastAlert.js";
import config from "../config/config";
import { useSelector } from "react-redux";
import {
  GetDetailsAction,
  getFormatMulticall,
  GetLevelDetailsAction,
  UpgradeAction,
  WithdrawAction,
  DepositAction,
} from "../Action/ContractActions.js";
import { connection } from "../helper/connection.js";
import Web3 from "web3";
import { Multicall } from "ethereum-multicall";
import ZolttanAbi from "../ABI/ZolttanABI.json";
import { bignumber } from "../helper/customMath.js";
import syncEach from "sync-each";
import moment from "moment";
import isEmpty from "is-empty";
import {
  checkuser,
  get_eligible,
  get_planAlist,
  updatemissed,
  get_levelcounts,
  withdrawhistory,
  withdrawlist,
  GlobalParticipantlist
} from "../Action/apiAction.js";
import { type WalletClient, useWalletClient } from "wagmi";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
var initialvalue = {
    userId:0,
    allusers:0,
    dirReferralcnt:0,
    upgradeAmount:0,
    yourearnedAmt:0,
    locktokenAmt:0,
    currentLevel:0,
    isexist:false
};

export default function Dashboard(props) {
  const [useraddress, setuseraddress] = useState("");
  const [upgradeLoad, setUpgradeLoad] = useState(false);
  const [depositLoad, setdepositLoad] = useState(false);
  const [withdrawLoad, setwithdrawLoad] = useState(false);
  const [userdetail, setuserdetail] = useState(initialvalue);
  const [selected_id, setselected_id] = useState("");
  const [depositamt, setdepositamt] = useState(0);
  const [level1counts, setlevel1counts] = useState(0);
  const [level2counts, setlevel2counts] = useState(0);
  const [level3counts, setlevel3counts] = useState(0);
  const [level4counts, setlevel4counts] = useState(0);
  const [level5counts, setlevel5counts] = useState(0);
  const [level6counts, setlevel6counts] = useState(0);
  const [level7counts, setlevel7counts] = useState(0);
  const [level8counts, setlevel8counts] = useState(0);
  const [level9counts, setlevel9counts] = useState(0);
  const [level10counts, setlevel10counts] = useState(0);
  const [level11counts, setlevel11counts] = useState(0);
  const [level12counts, setlevel12counts] = useState(0);
  const [level13counts, setlevel13counts] = useState(0);
  const [level14counts, setlevel14counts] = useState(0);
  const [level15counts, setlevel15counts] = useState(0);
  const [level16counts, setlevel16counts] = useState(0);
  const [level17counts, setlevel17counts] = useState(0);
  const [level18counts, setlevel18counts] = useState(0);
  const [level19counts, setlevel19counts] = useState(0);
  const [level20counts, setlevel20counts] = useState(0);
  const [level21counts, setlevel21counts] = useState(0);
  const [level22counts, setlevel22counts] = useState(0);
  const [level23counts, setlevel23counts] = useState(0);
  const [level24counts, setlevel24counts] = useState(0);
  const [level25counts, setlevel25counts] = useState(0);
  const [level26counts, setlevel26counts] = useState(0);
  const [level27counts, setlevel27counts] = useState(0);
  const [level28counts, setlevel28counts] = useState(0);
  const [level29counts, setlevel29counts] = useState(0);
  const [level30counts, setlevel30counts] = useState(0);
  const [userlegcount,setlegcount] = useState(0);
  const [royaltycount,setroyaltycount] = useState(0);
  const [royaltyamount,setroyaltyamount] = useState(0);
  const [last24hruser,setlast24hruser]= useState(0);
  const [profileinfo,setprofileinfo]= useState({});
  const [arraylegcount,setarraylegcount]= useState([]);

  const {
    userId,
    allusers,
    dirReferralcnt,
    upgradeAmount,
    yourearnedAmt,
    locktokenAmt,
    currentLevel,
    isexist
  } = userdetail;
  const [userdata, setdata] = useState({});
  const [treeprogress, settreeprogress] = useState(false);
  const [allselected, setallSelected] = useState({});
  const [mynetwork, setmynetwork] = useState([]);
  const [downlinefirst, setdownlinefirst] = useState([]);
  const [downlinesecond, setdownlinesecond] = useState([]);
  const [downlinethird, setdownlinethird] = useState([]);
  const [downlinefourth, setdownlinefourth] = useState([]);
  const [level1, setlevel1] = useState([]);
  const [level2, setlevel2] = useState([]);
  const [level3, setlevel3] = useState([]);
  const [selected, setSelected] = useState({});
  const [planprogress, setplanprogress] = useState(true);
  const [levelload, setlevelload] = useState(true);
  const [width, setwidth] = useState(window.innerWidth - 50);
  const [height, setheight] = useState(window.innerHeight - 50);
  const walletConnection = useSelector((state) => state.walletConnection);
  let chainid = config.NetworkId;
  const { data: walletClient } = useWalletClient({ chainid });
  const [joinedlength,setjoinedlength] = useState(0);
  const [claimprogress,setclaimprogress] = useState(false);
  const [historylist,sethistorylist]= useState([]);
  const [golbalroyaltycount,setgolbalroyaltycount]  = useState(0);
  const [globalroyaltyamount,setglobalroyaltyamount]= useState(0);

  useEffect(() => {
    window.addEventListener("resize", larg);
  }, []);

  useEffect(() => {
    getglobalparticipantlist()
    getdetails();
    getuserDetails();
    getlevelincomeDetails();
    getlevelcount();
  }, [walletConnection, walletClient]);

  async function getglobalparticipantlist(){
    let rec = await GlobalParticipantlist();
    if(rec && rec.data && rec.data.data){
      setgolbalroyaltycount(rec.data.data.length)
    }
  }

  async function gethistory(datas){
    let inputrec = {
      "address": datas.address
    }
    let rec = await withdrawlist(inputrec);
    if(rec && rec.data && rec.data.data){
      sethistorylist(rec.data.data)
    }
  }

  async function getregister(datas) {
    var get = await connection();
    if (get && get.web3) {
      let web3 = new Web3(get.web3);
      let address = get.address;
      var zolttancontract = new web3.eth.Contract(ZolttanAbi, config.ZolttanAddress);
      let userrefdetails = await zolttancontract.methods.planB(address).call();
      let referralparent = await zolttancontract.methods
        .userList(userrefdetails.referrerID)
        .call();
      let rec = {
        address: datas.address,
        userid: datas.userdetails.id,
        referralparent: referralparent,
        level: datas.userdetails.currentLevel,
      };
      let recs = await updatemissed(rec);
      if(recs && recs.data && recs.data.data){
        setprofileinfo(recs.data.data)
        let lastcount = recs.data.data.lastdistributedlegcount ? recs.data.data.lastdistributedlegcount  : 0 ;
        let currentcount = recs.data.data.legcount;
        let counts = parseFloat(currentcount)-parseFloat(lastcount);
        let royamt = parseFloat((counts * ((100000000000000000 * 5) / 100))/1e18).toFixed(5);
        setroyaltyamount(royamt);
      }
    }
  }

  const getdetails = async () => {
    try {
      var datas = await connection();
      if (datas && datas.web3 && datas.address) {
        var address = datas.address;
        setdata(datas);
        getlegcountdetails();
        if (datas.userdetails && datas.userdetails.isExist) {
          let user_data = {
            address: address,
          };
          let data = await checkuser(user_data);
          if (data && data.data && data.data.address) {
            setprofileinfo(data.data);
            let lastcount = data.data.lastdistributedlegcount ? data.data.lastdistributedlegcount  : 0 ;
            let currentcount = data.data.legcount;
            let counts = parseFloat(currentcount)-parseFloat(lastcount);
            let royamt = parseFloat((counts * ((100000000000000000 * 5) / 100))/1e18).toFixed(5);
            setroyaltyamount(royamt);
            getuserDetails(datas.address);
          } else {
            getregister(datas);
            gethistory(datas);
            getuserDetails(datas.address);
          }
        } else {
          window.location.href = "/join-now";
        }
      } else {
        //window.location.href = "/";
      }
    } catch (err) {
      console.log(err, "errrerertttttttt");
    }
  };

  async function getlegcountdetails(){
     try {
      var datas = await connection();
      if (datas && datas.web3 && datas.address) {
        var web3 = new Web3(datas.web3);
        let arr = [];
        var zolttanContract = new web3.eth.Contract(ZolttanAbi,config.ZolttanAddress);
        let records = await zolttanContract.methods.viewplanBUserReferral(datas.address).call();
        syncEach(records,async function (items, next) {
          let counts = await zolttanContract.methods.legCount(items).call();
          arr.push(counts);
          next();
        },function (err, transformedItems) {
          console.log(arr)
          setarraylegcount(arr);
        })
      }
    } catch(err) {
      console.log(err,"====errrorororororororor")
    }
  }

  const getuserDetails = async () => {
    let address =
      walletConnection &&
      walletConnection.address &&
      walletConnection.address != ""
        ? walletConnection.address
        : "";
    setuseraddress(address);
    let isexistcheck =
      walletConnection &&
      walletConnection.userdetails &&
      walletConnection.userdetails.isExist;
    if (!isEmpty(isexistcheck) && isexistcheck == false) {
      window.location.href = "/join-now";
    } else {
      const {
        status,
        isexist,
        UserProfileId,
        AllUsers,
        dirReferralcount,
        upgradeAmount,
        yourearnedAmt,
        locktokenAmt,
        currentLevel,
        joinedLength,
        totalEarnedamt,
        levelincome,
        referralincome,
        mgincome,
        mpincome,
        legcount,
        eligibleroyaltycount,
        totalroyaltyamt,
        totalglobalroyaltyamt,
        last_24hruser,
        joined_date,
        levelprice1,
        levelprice2,
        levelprice3,
        levelprice4,
        levelprice5,
        levelprice6,
        levelprice7,
        levelprice8,
        totroy,
        totglobroy

      } = await GetDetailsAction();
      setjoinedlength(joinedLength)
      setlegcount(legcount)
      setroyaltycount(eligibleroyaltycount);
      setglobalroyaltyamount(totalglobalroyaltyamt);
      //setroyaltyamount(totalroyaltyamt);
      setlast24hruser(last_24hruser)
      if (status == true) {
        setuserdetail({
          ...userdetail,
          ...{
            userId: UserProfileId,
            isexist: isexist,
            allusers: AllUsers,
            dirReferralcnt: dirReferralcount,
            upgradeAmount: upgradeAmount,
            yourearnedAmt: yourearnedAmt,
            locktokenAmt: locktokenAmt,
            currentLevel: currentLevel,
            totalEarnedamt : totalEarnedamt,
            levelincome : levelincome,
            referralincome : referralincome,
            mgincome : mgincome,
            mpincome : mpincome,
            joineddate : joined_date,
            levelprice1 : levelprice1/1e18,
            levelprice2 : levelprice2/1e18,
            levelprice3 : levelprice3/1e18,
            levelprice4 : levelprice4/1e18,
            levelprice5 : levelprice5/1e18,
            levelprice6 : levelprice6/1e18,
            levelprice7 : levelprice7/1e18,
            levelprice8 : levelprice8/1e18,
            totalroyaltyamt : totroy,
            totalglobalroyaltyamt : totglobroy
          },
        });
      } else {
        // window.location.href = '/'
      }
    }
  };

  const getlevelincomeDetails = async () => {
    let address =
      walletConnection &&
      walletConnection.address &&
      walletConnection.address != ""
        ? walletConnection.address
        : "";
    setuseraddress(address);
    let isexistcheck =
      walletConnection &&
      walletConnection.userdetails &&
      walletConnection.userdetails.isExist;
    if (!isEmpty(isexistcheck) && isexistcheck == false) {
      window.location.href = "/join-now";
    } else {
      const { status } = await GetLevelDetailsAction(
        settreeprogress,
        setallSelected,
        setmynetwork,
        setdownlinefirst,
        setdownlinesecond,
        setdownlinethird,
        setdownlinefourth,
        setlevel1,
        setlevel2,
        setlevel3,
        setSelected
      );

      if (status == true) {
        // setuserdetail({
        //   ...userdetail, ...{
        //     isexist: isexist,
        //   }
        // })
      } else {
        // window.location.href = '/'
      }
    }
  };

  const Upgrade = async () => {
    setUpgradeLoad(true);
    const { status, message } = await UpgradeAction(
      upgradeAmount,
      isexist,
      currentLevel
    );
    if (status == true) {
      getuserDetails();
      setUpgradeLoad(false);
      toastAlert("success", message);
    } else {
      setUpgradeLoad(false);
      toastAlert("error", message);
    }
  };

  const depositamtchange = async (e) => {
    setdepositamt(e.target.value);
  };

  const deposit_bnb = async () => {
    try {
      setdepositLoad(true);
      const { status, message } = await DepositAction(depositamt, isexist);
      if (status == true) {
        getuserDetails();
        setdepositLoad(false);
        toastAlert("success", message);
      } else {
        setdepositLoad(false);
        toastAlert("error", message);
      }
    } catch (err) {
      console.log(err, "ooooooooerererere");
    }
  };

  const Withdraw = async () => {
    try {
      setwithdrawLoad(true);
      const { status, message } = await WithdrawAction(
        yourearnedAmt,
        upgradeAmount,
        isexist,
        currentLevel
      );
      if (status == true) {
        getuserDetails();
        setwithdrawLoad(false);
        toastAlert("success", message);
      } else {
        setwithdrawLoad(false);
        toastAlert("error", message);
      }
    } catch (err) {
      console.log(err, "ooooooooerererere");
    }
  };

  async function larg() {
    setwidth(window.innerWidth - 50);
    setheight(window.innerHeight - 50);
  }

  async function claimrewards() {
    try{
    setclaimprogress(true);
    let get = await connection();
      if (get && get.web3) {
        var web3 = new Web3(get.web3);
        var zolttanContract = new web3.eth.Contract(ZolttanAbi,config.ZolttanAddress);
        let claimstatus = await zolttanContract.methods.claimlock().call();
        if(!claimstatus){
          await zolttanContract.methods.claimRewards().send({ from: get.address });
          let reqdata = {
            "address": get.address,
          }
          await withdrawhistory(reqdata);
          gethistory(reqdata);
          getglobalparticipantlist()
          getdetails();
          getuserDetails();
          getlevelincomeDetails();
          getlevelcount();
          setclaimprogress(false);
          toastAlert("success", "Claimed Successfully", "success");
        }else{
          setclaimprogress(false);
          toastAlert("error", "Claim not allowed", "error");
        }
      }else{
        setclaimprogress(false);
        toastAlert("error", "Connect Wallet to proceed Further", "error");
      }
    }catch(err){
      console.log(err,"====ClaimClaimClaimClaimClaim")
      setclaimprogress(false);
      toastAlert("error", " Failed Please Try again Later", "error");
    }

  }

  async function handleClick(event, node) {
    if (node && node != null && node != undefined && node != "") {
      let myArray = node.split(" ");
      let userid = myArray[1];
      if (selected_id == userid) {
        setSelected(allselected);
        setselected_id("");
      } else {
        settreeprogress(true);
        setselected_id(userid);
        let get = await connection();
        if (get && get.web3) {
          var web3 = new Web3(get.web3);
          const multicall = new Multicall({
            web3Instance: web3,
          });
          var zolttanContract = new web3.eth.Contract(
            ZolttanAbi,
            config.ZolttanAddress
          );
          let c_address = await zolttanContract.methods
            .planBuserList(userid)
            .call();

          let downlinenode = await zolttanContract.methods
            .viewUserReferral(c_address)
            .call();
          let leveldetails = await zolttanContract.methods.users(c_address).call();
          let currlevel = leveldetails && leveldetails.currentLevel;
          let downline1 = [];
          let downline2arr = [];
          let downline3arr = [];
          let downline4arr = [];
          let downline5arr = [];
          let downline2 = [];
          let downline3 = [];
          let downline4 = [];
          if (downlinenode.length > 0) {
            for (let node in downlinenode) {
              var downlineCallContext = [
                {
                  reference: "details",
                  contractAddress: config.ZolttanAddress,
                  abi: ZolttanAbi,
                  calls: [
                    {
                      reference: "userprofile",
                      methodName: "users",
                      methodParameters: [downlinenode[node]],
                    },
                    {
                      reference: "viewUserReferral",
                      methodName: "viewUserReferral",
                      methodParameters: [downlinenode[node]],
                    },
                  ],
                },
              ];
              const results1 = await multicall.call(downlineCallContext);
              var userprofile1 = await getFormatMulticall(
                results1,
                "userprofile",
                0
              );
              var downlinenode1 = await getFormatMulticall(
                results1,
                "viewUserReferral",
                0
              );
              let userid1 = await bignumber(userprofile1[1]);
              let currlevel1 = await bignumber(userprofile1[3]);
              let downline_1 = {
                address: downlinenode[node],
                level: currlevel1,
                userid: userid1,
                downlinenode: downlinenode1,
              };
              downline1.push(downline_1);
              downline2arr = downline2arr.concat(downlinenode1);
              if (parseFloat(node) + 1 == downlinenode.length) {
                setdownlinefirst(downline1);
              }
            }
          }
          //*******downline2*******//
          if (downline2arr.length > 0) {
            for (let node1 in downline2arr) {
              var downlineCallContext0 = [
                {
                  reference: "details",
                  contractAddress: config.ZolttanAddress,
                  abi: ZolttanAbi,
                  calls: [
                    {
                      reference: "userprofile",
                      methodName: "users",
                      methodParameters: [downline2arr[node1]],
                    },
                    {
                      reference: "viewUserReferral",
                      methodName: "viewUserReferral",
                      methodParameters: [downline2arr[node1]],
                    },
                  ],
                },
              ];
              const results2 = await multicall.call(downlineCallContext0);
              var userprofile2 = await getFormatMulticall(
                results2,
                "userprofile",
                0
              );
              var downlinenode2 = await getFormatMulticall(
                results2,
                "viewUserReferral",
                0
              );
              let userid2 = await bignumber(userprofile2[1]);
              let currlevel2 = await bignumber(userprofile2[3]);
              let downline_2 = {
                address: downline2arr[node1],
                level: currlevel2,
                userid: userid2,
                downlinenode: downlinenode2,
              };
              downline2.push(downline_2);
              downline3arr = downline3arr.concat(downlinenode2);
              if (parseFloat(node1) + 1 == downline2arr.length) {
                setdownlinesecond(downline2);
              }
            }
          }

          //************downline3******************************//
          if (downline3arr.length > 0) {
            for (let node2 in downline3arr) {
              var downlineCallContext1 = [
                {
                  reference: "details",
                  contractAddress: config.ZolttanAddress,
                  abi: ZolttanAbi,
                  calls: [
                    {
                      reference: "userprofile",
                      methodName: "users",
                      methodParameters: [downline3arr[node2]],
                    },
                    {
                      reference: "viewUserReferral",
                      methodName: "viewUserReferral",
                      methodParameters: [downline3arr[node2]],
                    },
                  ],
                },
              ];
              const results3 = await multicall.call(downlineCallContext1);
              var userprofile3 = await getFormatMulticall(
                results3,
                "userprofile",
                0
              );
              var downlinenode3 = await getFormatMulticall(
                results3,
                "viewUserReferral",
                0
              );
              let userid3 = await bignumber(userprofile3[1]);
              let currlevel3 = await bignumber(userprofile3[3]);
              let downline_3 = {
                address: downline3arr[node2],
                level: currlevel3,
                userid: userid3,
                downlinenode: downlinenode3,
              };
              downline3.push(downline_3);
              downline4arr = downline4arr.concat(downlinenode3);
              if (parseFloat(node2) + 1 == downline3arr.length) {
                setdownlinethird(downline3);
              }
            }
          }

          //************downline4******************************//
          if (downline4arr.length > 0) {
            for (let node3 in downline4arr) {
              var downlineCallContext3 = [
                {
                  reference: "details",
                  contractAddress: config.ZolttanAddress,
                  abi: ZolttanAbi,
                  calls: [
                    {
                      reference: "userprofile",
                      methodName: "users",
                      methodParameters: [downline4arr[node3]],
                    },
                    {
                      reference: "viewUserReferral",
                      methodName: "viewUserReferral",
                      methodParameters: [downline4arr[node3]],
                    },
                  ],
                },
              ];
              const results4 = await multicall.call(downlineCallContext3);
              var userprofile4 = await getFormatMulticall(
                results4,
                "userprofile",
                0
              );
              var downlinenode4 = await getFormatMulticall(
                results4,
                "viewUserReferral",
                0
              );
              let userid4 = await bignumber(userprofile4[1]);
              let currlevel4 = await bignumber(userprofile4[3]);
              let downline_4 = {
                address: downline4arr[node3],
                level: currlevel4,
                userid: userid4,
                downlinenode: downlinenode4,
              };
              downline4.push(downline_4);
              downline5arr = downline5arr.concat(downlinenode4);
              if (parseFloat(node3) + 1 == downline4arr.length) {
                setdownlinefourth(downline4);
              }
            }
          }

          let line1arr = [];
          if (downline1.length > 0) {
            syncEach(
              downline1,
              async function (items, next) {
                if (
                  items &&
                  items.downlinenode &&
                  items.downlinenode.length > 0
                ) {
                  let subline = [];
                  syncEach(
                    items.downlinenode,
                    async function (subitems, next1) {
                      let IndexVal = downline2.findIndex(
                        (val) => val.address == subitems
                      );
                      if (
                        IndexVal >= 0 &&
                        downline2[IndexVal].downlinenode &&
                        downline2[IndexVal].downlinenode.length > 0
                      ) {
                        let subline2 = [];
                        syncEach(
                          downline2[IndexVal].downlinenode,
                          async function (thir_items, next2) {
                            let IndexVal1 = downline3.findIndex(
                              (val) => val.address == thir_items
                            );
                            if (
                              IndexVal1 >= 0 &&
                              downline3[IndexVal1].downlinenode &&
                              downline3[IndexVal1].downlinenode.length > 0
                            ) {
                              let subline3 = [];
                              syncEach(
                                downline3[IndexVal1].downlinenode,
                                async function (fourth_items, next3) {
                                  let Index_Val = downline4.findIndex(
                                    (val) => val.address == fourth_items
                                  );
                                  let user22 = {
                                    name:
                                      "ID " +
                                      downline4[Index_Val].userid +
                                      " (" +
                                      downline4[Index_Val].level +
                                      ")",
                                  };
                                  subline3.push(user22);
                                  next3();
                                },
                                function (error, success) {
                                  let mainline = {
                                    name:
                                      "ID " +
                                      downline3[IndexVal1].userid +
                                      " (" +
                                      downline3[IndexVal1].level +
                                      ")",
                                    children: subline3,
                                  };
                                  setlevel3(mainline);
                                  subline2.push(mainline);
                                  next2();
                                }
                              );
                            } else {
                              let IndexVal = downline3.findIndex(
                                (val) => val.address == thir_items
                              );
                              let user1 = {
                                name:
                                  "ID " +
                                  downline3[IndexVal].userid +
                                  " (" +
                                  downline3[IndexVal].level +
                                  ")",
                              };
                              subline2.push(user1);
                              next2();
                            }
                            // let user12 = {
                            //     name : "ID "+downline3[IndexVal].userid+" ("+downline3[IndexVal].level+")"
                            // }
                            // subline2.push(user12);
                            // next2();
                          },
                          function (error, success) {
                            let mainline = {
                              name:
                                "ID " +
                                downline2[IndexVal].userid +
                                " (" +
                                downline2[IndexVal].level +
                                ")",
                              children: subline2,
                            };
                            setlevel2(mainline);
                            subline.push(mainline);
                            next1();
                          }
                        );
                      } else {
                        var IndexValue = downline2.findIndex(
                          (val) => val.address == subitems
                        );
                        let user1 = {
                          name:
                            "ID " +
                            downline2[IndexValue].userid +
                            " (" +
                            downline2[IndexValue].level +
                            ")",
                        };
                        subline.push(user1);
                        next1();
                      }
                    },
                    function (errs, t_Items) {
                      let mainline = {
                        name: "ID " + items.userid + " (" + items.level + ")",
                        children: subline,
                      };
                      setlevel1(mainline);
                      console.log("push33");
                      line1arr.push(mainline);
                      next();
                    }
                  );
                } else {
                  let mainline = {
                    name: "ID " + items.userid + " (" + items.level + ")",
                    children: [],
                  };
                  setlevel1(mainline);
                  setlevel2([]);
                  line1arr.push(mainline);
                  next();
                }
              },
              function (err, transformedItems) {
                let mainlinearr = {
                  name: "ID " + userid + " (" + currlevel + ")",
                  children: line1arr,
                };
                setSelected(mainlinearr);
                settreeprogress(false);
              }
            );
          } else {
            let mainlinearr = {
              name: "ID " + userid + " (" + currlevel + ")",
              children: [],
            };
            setSelected(mainlinearr);
            settreeprogress(false);
          }
        } else {
        }
      }
    }
  }

  async function copyToClipboard(e) {
    var textField = document.createElement("textarea");
    textField.innerText = config.Front_URL + "join-now/" + useraddress;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    toastAlert("success", "Copied Successfully", "success");
  }

  async function getlevelcount() {
    try {
      let get = await connection();
      if (get && get.web3) {
        const web3_bsc = new Web3(get.web3); //mainnet
        const ZolttanContract = new web3_bsc.eth.Contract(
          ZolttanAbi,
          config.ZolttanAddress
        );
        let referrallist = await ZolttanContract.methods
          .viewplanBUserReferral(get.address)
          .call();
        var l1users = referrallist;
        var l2users = [];
        var l3users = [];
        var l4users = [];
        var l5users = [];
        var l6users = [];
        var l7users = [];
        var l8users = [];
        var l9users = [];
        var l10users = [];
        var l11users = [];
        var l12users = [];
        var l13users = [];
        var l14users = [];
        var l15users = [];
        var l16users = [];
        var l17users = [];
        var l18users = [];
        var l19users = [];
        var l20users = [];
        var l21users = [];
        var l22users = [];
        var l23users = [];
        var l24users = [];
        var l25users = [];
        var l26users = [];
        var l27users = [];
        var l28users = [];
        var l29users = [];
        var l30users = [];
        setlevel1counts(l1users.length);
        syncEach(
          referrallist,
          async function (items, next) {
            let referrallist1 = await ZolttanContract.methods
              .viewplanBUserReferral(items)
              .call();
            l2users = l2users.concat(referrallist1);
            next();
          },
          async function (err, transformedItems) {
            setlevel2counts(l2users.length);
            syncEach(
              l2users,
              async function (items1, next1) {
                let referrallist2 = await ZolttanContract.methods
                  .viewplanBUserReferral(items1)
                  .call();
                l3users = l3users.concat(referrallist2);
                next1();
              },
              async function (err, transformedItems) {
                setlevel3counts(l3users.length);
                syncEach(
                  l3users,
                  async function (items2, next2) {
                    let referrallist3 = await ZolttanContract.methods
                      .viewplanBUserReferral(items2)
                      .call();
                    l4users = l4users.concat(referrallist3);
                    next2();
                  },
                  async function (err, transformedItems) {
                    setlevel4counts(l4users.length);
                    syncEach(
                      l4users,
                      async function (items3, next3) {
                        let referrallist4 = await ZolttanContract.methods
                          .viewplanBUserReferral(items3)
                          .call();
                        l5users = l5users.concat(referrallist4);
                        next3();
                      },
                      async function (err, transformedItems) {
                        setlevel5counts(l5users.length);
                        syncEach(
                          l5users,
                          async function (items4, next4) {
                            let referrallist5 = await ZolttanContract.methods
                              .viewplanBUserReferral(items4)
                              .call();
                            l6users = l6users.concat(referrallist5);
                            next4();
                          },
                          async function (err, transformedItems) {
                            setlevel6counts(l6users.length);
                            syncEach(
                              l6users,
                              async function (items5, next5) {
                                let referrallist6 = await ZolttanContract.methods
                                  .viewplanBUserReferral(items5)
                                  .call();
                                l7users = l7users.concat(referrallist6);
                                next5();
                              },
                              async function (err, transformedItems) {
                                setlevel7counts(l7users.length);
                                syncEach(
                                  l7users,
                                  async function (items6, next6) {
                                    let referrallist7 =
                                      await ZolttanContract.methods
                                        .viewplanBUserReferral(items6)
                                        .call();
                                    l8users = l8users.concat(referrallist7);
                                    next6();
                                  },
                                  async function (err, transformedItems) {
                                    setlevel8counts(l8users.length);
                                    syncEach(
                                      l8users,
                                      async function (items7, next7) {
                                        let referrallist8 =
                                          await ZolttanContract.methods
                                            .viewplanBUserReferral(items7)
                                            .call();
                                        l9users = l9users.concat(referrallist8);
                                        next7();
                                      },
                                      async function (err, transformedItems) {
                                        setlevel9counts(l9users.length);
                                        syncEach(
                                          l9users,
                                          async function (items8, next8) {
                                            let referrallist9 =
                                              await ZolttanContract.methods
                                                .viewplanBUserReferral(items8)
                                                .call();
                                            l10users =
                                              l10users.concat(referrallist9);
                                            next8();
                                          },
                                          async function (
                                            err,
                                            transformedItems
                                          ) {
                                            setlevel10counts(l10users.length);
                                            syncEach(
                                              l10users,
                                              async function (items9, next9) {
                                                let referrallist10 =
                                                  await ZolttanContract.methods
                                                    .viewplanBUserReferral(
                                                      items9
                                                    )
                                                    .call();
                                                l11users =
                                                  l11users.concat(
                                                    referrallist10
                                                  );
                                                next9();
                                              },
                                              async function (
                                                err,
                                                transformedItems
                                              ) {
                                                setlevel11counts(
                                                  l11users.length
                                                );

                                                syncEach(
                                                  l11users,
                                                  async function (
                                                    items10,
                                                    next10
                                                  ) {
                                                    let referrallist11 =
                                                      await ZolttanContract.methods
                                                        .viewplanBUserReferral(
                                                          items10
                                                        )
                                                        .call();
                                                    l12users =
                                                      l12users.concat(
                                                        referrallist11
                                                      );
                                                    next10();
                                                  },
                                                  async function (
                                                    err,
                                                    transformedItems
                                                  ) {
                                                    setlevel12counts(
                                                      l12users.length
                                                    );

                                                    syncEach(
                                                      l12users,
                                                      async function (
                                                        items11,
                                                        next11
                                                      ) {
                                                        let referrallist12 =
                                                          await ZolttanContract.methods
                                                            .viewplanBUserReferral(
                                                              items11
                                                            )
                                                            .call();
                                                        l13users =
                                                          l13users.concat(
                                                            referrallist12
                                                          );
                                                        next11();
                                                      },
                                                      async function (
                                                        err,
                                                        transformedItems
                                                      ) {
                                                        setlevel13counts(
                                                          l13users.length
                                                        );

                                                        syncEach(
                                                          l13users,
                                                          async function (
                                                            items12,
                                                            next12
                                                          ) {
                                                            let referrallist13 =
                                                              await ZolttanContract.methods
                                                                .viewplanBUserReferral(
                                                                  items12
                                                                )
                                                                .call();
                                                            l14users =
                                                              l14users.concat(
                                                                referrallist13
                                                              );
                                                            next12();
                                                          },
                                                          async function (
                                                            err,
                                                            transformedItems
                                                          ) {
                                                            setlevel14counts(
                                                              l14users.length
                                                            );

                                                            syncEach(
                                                              l14users,
                                                              async function (
                                                                items13,
                                                                next13
                                                              ) {
                                                                let referrallist14 =
                                                                  await ZolttanContract.methods
                                                                    .viewplanBUserReferral(
                                                                      items13
                                                                    )
                                                                    .call();
                                                                l15users =
                                                                  l15users.concat(
                                                                    referrallist14
                                                                  );
                                                                next13();
                                                              },
                                                              async function (
                                                                err,
                                                                transformedItems
                                                              ) {
                                                                setlevel15counts(
                                                                  l15users.length
                                                                );

                                                                syncEach(
                                                                  l15users,
                                                                  async function (
                                                                    items14,
                                                                    next14
                                                                  ) {
                                                                    let referrallist15 =
                                                                      await ZolttanContract.methods
                                                                        .viewplanBUserReferral(
                                                                          items14
                                                                        )
                                                                        .call();
                                                                    l16users =
                                                                      l16users.concat(
                                                                        referrallist15
                                                                      );
                                                                    next14();
                                                                  },
                                                                  async function (
                                                                    err,
                                                                    transformedItems
                                                                  ) {
                                                                    setlevel16counts(
                                                                      l16users.length
                                                                    );

                                                                    syncEach(
                                                                      l16users,
                                                                      async function (
                                                                        items15,
                                                                        next15
                                                                      ) {
                                                                        let referrallist16 =
                                                                          await ZolttanContract.methods
                                                                            .viewplanBUserReferral(
                                                                              items15
                                                                            )
                                                                            .call();
                                                                        l17users =
                                                                          l17users.concat(
                                                                            referrallist16
                                                                          );
                                                                        next15();
                                                                      },
                                                                      async function (
                                                                        err,
                                                                        transformedItems
                                                                      ) {
                                                                        setlevel17counts(
                                                                          l17users.length
                                                                        );

                                                                        syncEach(
                                                                          l17users,
                                                                          async function (
                                                                            items16,
                                                                            next16
                                                                          ) {
                                                                            let referrallist17 =
                                                                              await ZolttanContract.methods
                                                                                .viewplanBUserReferral(
                                                                                  items16
                                                                                )
                                                                                .call();
                                                                            l18users =
                                                                              l18users.concat(
                                                                                referrallist17
                                                                              );
                                                                            next16();
                                                                          },
                                                                          async function (
                                                                            err,
                                                                            transformedItems
                                                                          ) {
                                                                            setlevel18counts(
                                                                              l18users.length
                                                                            );

                                                                            syncEach(
                                                                              l18users,
                                                                              async function (
                                                                                items17,
                                                                                next17
                                                                              ) {
                                                                                let referrallist18 =
                                                                                  await ZolttanContract.methods
                                                                                    .viewplanBUserReferral(
                                                                                      items17
                                                                                    )
                                                                                    .call();
                                                                                l19users =
                                                                                  l19users.concat(
                                                                                    referrallist18
                                                                                  );
                                                                                next17();
                                                                              },
                                                                              async function (
                                                                                err,
                                                                                transformedItems
                                                                              ) {
                                                                                setlevel19counts(
                                                                                  l19users.length
                                                                                );

                                                                                syncEach(
                                                                                  l19users,
                                                                                  async function (
                                                                                    items18,
                                                                                    next18
                                                                                  ) {
                                                                                    let referrallist19 =
                                                                                      await ZolttanContract.methods
                                                                                        .viewplanBUserReferral(
                                                                                          items18
                                                                                        )
                                                                                        .call();
                                                                                    l20users =
                                                                                      l20users.concat(
                                                                                        referrallist19
                                                                                      );
                                                                                    next18();
                                                                                  },
                                                                                  async function (
                                                                                    err,
                                                                                    transformedItems
                                                                                  ) {
                                                                                    setlevel20counts(
                                                                                      l20users.length
                                                                                    );

                                                                                    syncEach(
                                                                                      l20users,
                                                                                      async function (
                                                                                        items19,
                                                                                        next19
                                                                                      ) {
                                                                                        let referrallist20 =
                                                                                          await ZolttanContract.methods
                                                                                            .viewplanBUserReferral(
                                                                                              items19
                                                                                            )
                                                                                            .call();
                                                                                        l21users =
                                                                                          l21users.concat(
                                                                                            referrallist20
                                                                                          );
                                                                                        next19();
                                                                                      },
                                                                                      async function (
                                                                                        err,
                                                                                        transformedItems
                                                                                      ) {
                                                                                        setlevel21counts(
                                                                                          l21users.length
                                                                                        );

                                                                                        syncEach(
                                                                                          l21users,
                                                                                          async function (
                                                                                            items20,
                                                                                            next20
                                                                                          ) {
                                                                                            let referrallist21 =
                                                                                              await ZolttanContract.methods
                                                                                                .viewplanBUserReferral(
                                                                                                  items20
                                                                                                )
                                                                                                .call();
                                                                                            l22users =
                                                                                              l22users.concat(
                                                                                                referrallist21
                                                                                              );
                                                                                            next20();
                                                                                          },
                                                                                          async function (
                                                                                            err,
                                                                                            transformedItems
                                                                                          ) {
                                                                                            setlevel22counts(
                                                                                              l22users.length
                                                                                            );

                                                                                            syncEach(
                                                                                              l22users,
                                                                                              async function (
                                                                                                items21,
                                                                                                next21
                                                                                              ) {
                                                                                                let referrallist22 =
                                                                                                  await ZolttanContract.methods
                                                                                                    .viewplanBUserReferral(
                                                                                                      items21
                                                                                                    )
                                                                                                    .call();
                                                                                                l23users =
                                                                                                  l23users.concat(
                                                                                                    referrallist22
                                                                                                  );
                                                                                                next21();
                                                                                              },
                                                                                              async function (
                                                                                                err,
                                                                                                transformedItems
                                                                                              ) {
                                                                                                setlevel23counts(
                                                                                                  l23users.length
                                                                                                );
                                                                                                syncEach(
                                                                                                  l23users,
                                                                                                  async function (
                                                                                                    items22,
                                                                                                    next22
                                                                                                  ) {
                                                                                                    let referrallist23 =
                                                                                                      await ZolttanContract.methods
                                                                                                        .viewplanBUserReferral(
                                                                                                          items22
                                                                                                        )
                                                                                                        .call();
                                                                                                    l24users =
                                                                                                      l24users.concat(
                                                                                                        referrallist23
                                                                                                      );
                                                                                                    next22();
                                                                                                  },
                                                                                                  async function (
                                                                                                    err,
                                                                                                    transformedItems
                                                                                                  ) {
                                                                                                    setlevel24counts(
                                                                                                      l24users.length
                                                                                                    );

                                                                                                    syncEach(
                                                                                                      l24users,
                                                                                                      async function (
                                                                                                        items23,
                                                                                                        next23
                                                                                                      ) {
                                                                                                        let referrallist24 =
                                                                                                          await ZolttanContract.methods
                                                                                                            .viewplanBUserReferral(
                                                                                                              items23
                                                                                                            )
                                                                                                            .call();
                                                                                                        l25users =
                                                                                                          l25users.concat(
                                                                                                            referrallist24
                                                                                                          );
                                                                                                        next23();
                                                                                                      },
                                                                                                      async function (
                                                                                                        err,
                                                                                                        transformedItems
                                                                                                      ) {
                                                                                                        setlevel25counts(
                                                                                                          l25users.length
                                                                                                        );
                                                                                                        syncEach(
                                                                                                          l25users,
                                                                                                          async function (
                                                                                                            items24,
                                                                                                            next24
                                                                                                          ) {
                                                                                                            let referrallist25 =
                                                                                                              await ZolttanContract.methods
                                                                                                                .viewplanBUserReferral(
                                                                                                                  items24
                                                                                                                )
                                                                                                                .call();
                                                                                                            l26users =
                                                                                                              l26users.concat(
                                                                                                                referrallist25
                                                                                                              );
                                                                                                            next24();
                                                                                                          },
                                                                                                          async function (
                                                                                                            err,
                                                                                                            transformedItems
                                                                                                          ) {
                                                                                                            setlevel26counts(
                                                                                                              l26users.length
                                                                                                            );
                                                                                                            syncEach(
                                                                                                              l26users,
                                                                                                              async function (
                                                                                                                items25,
                                                                                                                next25
                                                                                                              ) {
                                                                                                                let referrallist26 =
                                                                                                                  await ZolttanContract.methods
                                                                                                                    .viewplanBUserReferral(
                                                                                                                      items25
                                                                                                                    )
                                                                                                                    .call();
                                                                                                                l27users =
                                                                                                                  l27users.concat(
                                                                                                                    referrallist26
                                                                                                                  );
                                                                                                                next25();
                                                                                                              },
                                                                                                              async function (
                                                                                                                err,
                                                                                                                transformedItems
                                                                                                              ) {
                                                                                                                setlevel27counts(
                                                                                                                  l27users.length
                                                                                                                );
                                                                                                                syncEach(
                                                                                                                  l27users,
                                                                                                                  async function (
                                                                                                                    items26,
                                                                                                                    next26
                                                                                                                  ) {
                                                                                                                    let referrallist27 =
                                                                                                                      await ZolttanContract.methods
                                                                                                                        .viewplanBUserReferral(
                                                                                                                          items26
                                                                                                                        )
                                                                                                                        .call();
                                                                                                                    l28users =
                                                                                                                      l28users.concat(
                                                                                                                        referrallist27
                                                                                                                      );
                                                                                                                    next26();
                                                                                                                  },
                                                                                                                  async function (
                                                                                                                    err,
                                                                                                                    transformedItems
                                                                                                                  ) {
                                                                                                                    setlevel28counts(
                                                                                                                      l28users.length
                                                                                                                    );
                                                                                                                    syncEach(
                                                                                                                      l28users,
                                                                                                                      async function (
                                                                                                                        items27,
                                                                                                                        next27
                                                                                                                      ) {
                                                                                                                        let referrallist28 =
                                                                                                                          await ZolttanContract.methods
                                                                                                                            .viewplanBUserReferral(
                                                                                                                              items27
                                                                                                                            )
                                                                                                                            .call();
                                                                                                                        l29users =
                                                                                                                          l29users.concat(
                                                                                                                            referrallist28
                                                                                                                          );
                                                                                                                        next27();
                                                                                                                      },
                                                                                                                      async function (
                                                                                                                        err,
                                                                                                                        transformedItems
                                                                                                                      ) {
                                                                                                                        setlevel29counts(
                                                                                                                          l29users.length
                                                                                                                        );
                                                                                                                        syncEach(
                                                                                                                          l29users,
                                                                                                                          async function (
                                                                                                                            items28,
                                                                                                                            next28
                                                                                                                          ) {
                                                                                                                            let referrallist29 =
                                                                                                                              await ZolttanContract.methods
                                                                                                                                .viewplanBUserReferral(
                                                                                                                                  items28
                                                                                                                                )
                                                                                                                                .call();
                                                                                                                            l30users =
                                                                                                                              l30users.concat(
                                                                                                                                referrallist29
                                                                                                                              );
                                                                                                                            next28();
                                                                                                                          },
                                                                                                                          async function (
                                                                                                                            err,
                                                                                                                            transformedItems
                                                                                                                          ) {
                                                                                                                            setlevel30counts(
                                                                                                                              l30users.length
                                                                                                                            );
                                                                                                                          }
                                                                                                                        );
                                                                                                                      }
                                                                                                                    );
                                                                                                                  }
                                                                                                                );
                                                                                                              }
                                                                                                            );
                                                                                                          }
                                                                                                        );
                                                                                                      }
                                                                                                    );
                                                                                                  }
                                                                                                );
                                                                                              }
                                                                                            );
                                                                                          }
                                                                                        );
                                                                                      }
                                                                                    );
                                                                                  }
                                                                                );
                                                                              }
                                                                            );
                                                                          }
                                                                        );
                                                                      }
                                                                    );
                                                                  }
                                                                );
                                                              }
                                                            );
                                                          }
                                                        );
                                                      }
                                                    );
                                                  }
                                                );
                                              }
                                            );
                                          }
                                        );
                                      }
                                    );
                                  }
                                );
                              }
                            );
                          }
                        );
                      }
                    );
                  }
                );
              }
            );
          }
        );
      }
    } catch (errs) {
      console.log(errs, "======errororororororororor");
    }
  }

  console.log(profileinfo,"===profileinfoprofileinfoprofileinfo")

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="inner_wrapper">
        <div className="container">
          <div className="dashboard_panel">
            <div className="row">
              <div className="col-md-12">
                <div className="dash_title_flex">
                  <h2 className="inner_title">Dashboard</h2>
                  <div>
                    <div className="wallet_div">
                      <label>Affiliate Link: </label>
                      <div className="inp_grp">
                        <input
                          type="text"
                          className="form-control primary_inp"
                          // defaultValue="https://Zolttan.io/i/g9pnqp/"
                          value={config.Front_URL + "join-now/" + useraddress}
                        />
                        <button className="copy_btn" onClick={copyToClipboard}>
                          <img
                            src={require("../assets/images/copy_icon.png")}
                            className="img-fluid"
                            alt="Copy"
                          />
                        </button>
                      </div>
                    </div>
                    <div>
                      <label className="me-2">Date of Joining: </label>
                      <span>{userdetail && userdetail.joineddate ?  moment.unix(userdetail.joineddate).format("MM/DD/YYYY"):"-"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="dashboard_top_wrap">
              <div className="row">
                <div className="col-lg-3">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="primary_box dash_box side_box">
                        <div>
                          <div className="dashbox_middle_bottom">
                            <h5>ID No.</h5>
                            <h2 className="mb-0">{userId ? userId : 0}</h2>
                          </div>
                          <img
                            src={require("../assets/images/dash_icon_01.png")}
                            className="img-fluid"
                            alt="Icon"
                          />
                        </div>
                        <div>
                          <div className="dashbox_middle_bottom">
                            <h5>Total Registered ID's</h5>
                            <h2 className="mb-0">{joinedlength}</h2>
                          </div>
                        </div>
                        <div>
                          <div className="dashbox_middle_bottom">
                            <h5>Today Registered ID's</h5>
                            <h2 className="mb-0">{last24hruser}</h2>
                          </div>
                        </div>
                        <div>
                          <div className="dashbox_middle_bottom">
                            <h5>My Team Total ID's</h5>
                            <h2 className="mb-0">{userlegcount}</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="primary_box dash_box side_box">
                        <div>
                          <div className="dashbox_middle_bottom">
                            <h5>Royalty Advancement Count</h5>
                            <h2 className="mb-0">{golbalroyaltycount}</h2>
                          </div>
                          <img
                            src={require("../assets/images/dash_icon_02.png")}
                            className="img-fluid"
                            alt="Icon"
                          />
                        </div>
                        <div>
                          <div className="dashbox_middle_bottom">
                            <h5>Royalty Advancement Fund</h5>
                            <h2 className="mb-0">{(parseFloat(globalroyaltyamount)/1e18).toFixed(5)}</h2>
                          </div>
                        </div>
                        <div>
                          <div className="dashbox_middle_bottom">
                            <h5>Team Advancement Count</h5>
                            <h2 className="mb-0">{profileinfo && profileinfo.teameligiblecount ? profileinfo.teameligiblecount : 0}</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="primary_box dash_box side_box">
                        <div>
                          <div className="dashbox_middle_bottom">
                            <h5>Direct Referral Count</h5>
                            <h2 className="mb-0">
                              {userdetail && userdetail.dirReferralcnt ? userdetail.dirReferralcnt : 0}
                            </h2>
                          </div>
                          <img
                            src={require("../assets/images/dash_icon_03.png")}
                            className="img-fluid"
                            alt="Icon"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="primary_box dash_box side_box">
                        <div>
                          <div className="dashbox_middle_bottom">
                            <h5>Team Advancement Fund</h5>
                            <h2 className="mb-0">
                              {royaltyamount}
                            </h2>
                          </div>
                          <img
                            src={require("../assets/images/dash_icon_04.png")}
                            className="img-fluid"
                            alt="Icon"
                          />
                        </div>
                      </div>
                    </div>

                    {/*<div className="col-lg-12">
                      <div className="primary_box dash_box side_box">
                        <div>
                          <div className="dashbox_middle_bottom">
                            <h2 className="mb-0">Direct Team Count</h2>
                          </div>
                          <img
                            src={require("../assets/images/dash_icon_05.png")}
                            className="img-fluid"
                            alt="Icon"
                          />
                        </div>
                        <div className="scroll" >
                        {arraylegcount && arraylegcount.length>0 && arraylegcount.map((item,index)=>{
                          return(
                            <div>
                              <div className="dashbox_middle_bottom">
                                <h5>{ index==0? (parseInt(index)+1) +" "+"st Direct Team" : index ==1 ? (parseInt(index)+1) +" "+"nd Direct Team" : index==2 ? (parseInt(index)+1) +" "+"rd Direct Team" : (parseInt(index)+1) +" "+"th Direct Team"}</h5>
                                <h2 className="mb-0">{item}</h2>
                              </div>
                            </div>
                            )
                          })}
                          </div>
                      </div>
                    </div>*/}
                  </div>
                </div>

                <div className="col-lg-9">
                  {/* Your Income */}

                  <div className="balance_box">
                    <div>
                      <label>{userdetail && userdetail.totalEarnedamt ? parseFloat(userdetail.totalEarnedamt).toFixed(5) : 0 }{" "}
                        <span>BNB</span>
                      </label>
                      <label>Total Income Earned</label>
                    </div>
                    <div>
                      <label>
                        {userdetail && userdetail.levelincome ? parseFloat(userdetail.levelincome).toFixed(5) : 0 }{" "}<span>BNB</span>
                      </label>
                      <label>Generation Income</label>
                    </div>
                    <div>
                      <label>
                        {userdetail && userdetail.referralincome ? parseFloat(userdetail.referralincome).toFixed(5) : 0 }{" "}
                        <span>BNB</span>
                      </label>
                      <label>Referral Income</label>
                    </div>
                  </div>

                  <div className="balance_box balance_box_two_div">
                    <div>
                      <label>{userdetail && userdetail.locktokenAmt ? userdetail.locktokenAmt : 0 }{" "}
                        <span>BNB</span>
                      </label>
                      <label>Upgrade Amount</label>
                    </div>
                    <div>
                      <label>
                       {userdetail && userdetail.yourearnedAmt ? userdetail.yourearnedAmt : 0 }{" "}<span>BNB</span>
                      </label>
                      <label>Withdraw Amount</label>
                      <button class="primary_btn" onClick={()=>claimrewards()}>
                          {claimprogress ? "Processing..." :  "Claim"}</button>
                    </div>                    
                  </div>

                  <div className="primary_box dash_box">
                    <div className="dashbox_middle_bottom">
                      <h2>Your Income</h2>
                      <div className="plan_income_table_div">
                        <div className="table-responsive">
                          <table className="table plan_income_table mt-0">
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Income</th>
                                <th>Amount (BNB)</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>1</td>
                                <td>Direct Referral Income</td>
                                <td>{userdetail && userdetail.referralincome ? userdetail.referralincome : 0 }</td>
                              </tr>
                              <tr>
                                <td>2</td>
                                <td>Matrix Generation Income</td>
                                <td>{userdetail && userdetail.mgincome ? userdetail.mgincome : 0 }</td>
                              </tr>
                              <tr>
                                <td>3</td>
                                <td>Matrix Passive Income</td>
                                <td>{userdetail && userdetail.mpincome ? userdetail.mpincome : 0 }</td>
                              </tr>
                              <tr>
                                <td>4</td>
                                <td>Team Advancement Royalty Income</td>
                                <td>{userdetail && userdetail.totalroyaltyamt  && userdetail.totalroyaltyamt >0 ? (parseFloat(userdetail.totalroyaltyamt)/1e18).toFixed(5) : 0}</td>
                              </tr>
                              <tr>
                                <td>5</td>
                                <td>Global Royalty Income</td>
                                <td>{userdetail && userdetail.totalglobalroyaltyamt ? (parseFloat(userdetail.totalglobalroyaltyamt)/1e18).toFixed(5)  : 0}</td>
                              </tr>
                              <tr>
                                <td>6</td>
                                <td>Current Period Team Advancement Income</td>
                                <td>{profileinfo && profileinfo.pastroyality ? (parseFloat(profileinfo.pastroyality)/1e18).toFixed(5)  : 0}</td>
                              </tr>
                              <tr>
                                <td>7</td>
                                <td>Current Period Global Royalty Income</td>
                                <td>{profileinfo && profileinfo.pastglobalroyality_A ? (parseFloat(profileinfo.pastglobalroyality_A)/1e18).toFixed(5)  : 0}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Upgrade Matrix Income */}
                  <div className="primary_box dash_box mt-4">
                    <div className="dashbox_middle_bottom">
                      <div className="dash_flex_header">
                        <h2 class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne">Matrix Generate Income</h2>
                        {/* <div className="dash_btn_grp">
                          {parseInt(currentLevel) != 10 ? (
                            upgradeLoad == true ? (
                              <button className="primary_btn disabled" disabled>
                                Processing...
                              </button>
                            ) : (
                              <button className="primary_btn" onClick={Upgrade}>
                                Upgrade
                              </button>
                            )
                          ) : (
                            ""
                          )}
                          {withdrawLoad == true ? (
                            <button className="primary_btn disabled" disabled>
                              Processing...
                            </button>
                          ) : (
                            <button className="primary_btn" onClick={Withdraw}>
                              Withdraw
                            </button>
                          )}
                          {parseInt(currentLevel) != 10 && (
                            <button
                              className="primary_btn"
                              data-bs-toggle="modal"
                              data-bs-target="#wallet_modal"
                            >
                              Deposit
                            </button>
                          )}
                        </div> */}
            
                      </div>
                      <div className="accordion-collapse collapse" id="collapseOne">
                        {/*<div className="text-end">
                          <button className="primary_btn primary_btn_right" onClick={()=>claimrewards()}>
                            {claimprogress ? "Processing..." :  "Claim"}
                          </button>
                       </div>*/}

                       <div className="plan_wrapper">
                        <div className="buy_item">
                          <div className="buy_item_inner_left">
                            <div>
                              <h4>Level 1</h4>
                              <h2>
                                0.001 <span>BNB</span>
                              </h2>
                            </div>
                            <div>
                              <h4>Remaining BNB</h4>
                              <h5>
                                0 <span>BNB</span>
                              </h5>
                            </div>
                          </div>
                          <div className="buy_item_inner_right">
                            <div class="progress progress-bar-vertical progress_custom">
                              <div
                                class="progress-bar"
                                role="progressbar"
                                style={{ height: "100%" }}
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                100 %
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={userdetail && userdetail.currentLevel && userdetail.currentLevel>=1 ? "buy_item" : "buy_item buy_item_disabled"}>
                          <div className="buy_item_inner_left">
                            <div>
                              <h4>Level 2</h4>
                              <h2>
                                {userdetail && userdetail.levelprice2}<span>BNB</span>
                              </h2>
                            </div>
                            <div>
                              <h4>Remaining BNB</h4>
                              <h5>
                                { userdetail && userdetail.currentLevel && userdetail.currentLevel==1 ? (parseFloat(userdetail && userdetail.levelprice2) - parseFloat(userdetail.upgradeAmount)).toFixed(5) : 
                                  userdetail && userdetail.currentLevel && userdetail.currentLevel<1 ? userdetail && userdetail.levelprice2 : 0  }<span> BNB</span>
                              </h5>
                            </div>
                          </div>
                          <div className="buy_item_inner_right">
                            <div class="progress progress-bar-vertical progress_custom">
                              <div
                                class="progress-bar"
                                role="progressbar"
                                style={{ height: `${ userdetail && userdetail.currentLevel && userdetail.currentLevel==1 ? ((userdetail.upgradeAmount)*100)/userdetail.levelprice2 :
                                  userdetail && userdetail.currentLevel && userdetail.currentLevel<1 ? 0 : 100}%` }}
                                aria-valuenow={ userdetail && userdetail.currentLevel && userdetail.currentLevel==1 ?  ((userdetail.upgradeAmount)*100)/userdetail.levelprice2 :
                                  userdetail && userdetail.currentLevel && userdetail.currentLevel<1 ? 0 : 100}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                { userdetail && userdetail.currentLevel && userdetail.currentLevel==1 ? (((userdetail.upgradeAmount)*100)/userdetail.levelprice2).toFixed(0): 
                                  userdetail && userdetail.currentLevel && userdetail.currentLevel<1 ? 0 : 100} %
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={userdetail && userdetail.currentLevel && userdetail.currentLevel>=2 ? "buy_item" : "buy_item buy_item_disabled"}>
                          <div className="buy_item_inner_left">
                            <div>
                              <h4>Level 3</h4>
                              <h2>
                                {userdetail && userdetail.levelprice3} <span>BNB</span>
                              </h2>
                            </div>
                            <div>
                              <h4>Remaining BNB</h4>
                              <h5>
                               { userdetail && userdetail.currentLevel && userdetail.currentLevel==2 ? (parseFloat(userdetail && userdetail.levelprice3) - parseFloat(userdetail.upgradeAmount)).toFixed(5) : 
                                  userdetail && userdetail.currentLevel && userdetail.currentLevel<2 ? userdetail && userdetail.levelprice3 : 0  } <span> BNB</span>
                              </h5>
                            </div>
                          </div>
                          <div className="buy_item_inner_right">
                            <div class="progress progress-bar-vertical progress_custom">
                              <div
                                class="progress-bar"
                                role="progressbar"
                                style={{ height: `${ userdetail && userdetail.currentLevel && userdetail.currentLevel==2 ? ((userdetail.upgradeAmount)*100)/userdetail.levelprice3 :
                                  userdetail && userdetail.currentLevel && userdetail.currentLevel<2 ? 0 : 100}%` }}
                                aria-valuenow={ userdetail && userdetail.currentLevel && userdetail.currentLevel==2 ? ((userdetail.upgradeAmount)*100)/userdetail.levelprice3 :
                                  userdetail && userdetail.currentLevel && userdetail.currentLevel<2 ? 0 : 100}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                { userdetail && userdetail.currentLevel && userdetail.currentLevel==2 ? (((userdetail.upgradeAmount)*100)/userdetail.levelprice3).toFixed(0) :
                                  userdetail && userdetail.currentLevel && userdetail.currentLevel<2 ? 0 : 100}%
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={userdetail && userdetail.currentLevel && userdetail.currentLevel>=3 ? "buy_item" : "buy_item buy_item_disabled"}>
                          <div className="buy_item_inner_left">
                            <div>
                              <h4>Level 4</h4>
                              <h2>
                                {userdetail && userdetail.levelprice4} <span>BNB</span>
                              </h2>
                            </div>
                            <div>
                              <h4>Remaining BNB</h4>
                              <h5>
                                { userdetail && userdetail.currentLevel && userdetail.currentLevel==3 ? (parseFloat(userdetail && userdetail.levelprice4) - parseFloat(userdetail.upgradeAmount)).toFixed(5) : 
                                  userdetail && userdetail.currentLevel && userdetail.currentLevel<3 ? userdetail && userdetail.levelprice4 : 0  } <span>BNB</span>
                              </h5>
                            </div>
                          </div>
                          <div className="buy_item_inner_right">
                            <div class="progress progress-bar-vertical progress_custom">
                              <div
                                class="progress-bar"
                                role="progressbar"
                                style={{ height: `${ userdetail && userdetail.currentLevel && userdetail.currentLevel==3 ? ((userdetail.upgradeAmount)*100)/userdetail.levelprice4 :
                                  userdetail && userdetail.currentLevel && userdetail.currentLevel<3 ? 0 : 100}%` }}
                                aria-valuenow={ userdetail && userdetail.currentLevel && userdetail.currentLevel==3 ? ((userdetail.upgradeAmount)*100)/userdetail.levelprice4 :
                                  userdetail && userdetail.currentLevel && userdetail.currentLevel<3 ? 0 : 100}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                { userdetail && userdetail.currentLevel && userdetail.currentLevel==3 ? (((userdetail.upgradeAmount)*100)/userdetail.levelprice4).toFixed(0) :
                                  userdetail && userdetail.currentLevel && userdetail.currentLevel<3 ? 0 : 100}%
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={userdetail && userdetail.currentLevel && userdetail.currentLevel>=4 ? "buy_item" : "buy_item buy_item_disabled"}>
                          <div className="buy_item_inner_left">
                            <div>
                              <h4>Level 5</h4>
                              <h2>
                                {userdetail && userdetail.levelprice5} <span>BNB</span>
                              </h2>
                            </div>
                            <div>
                              <h4>Remaining BNB</h4>
                              <h5>
                                { userdetail && userdetail.currentLevel && userdetail.currentLevel==4 ? (parseFloat(userdetail && userdetail.levelprice5) - parseFloat(userdetail.upgradeAmount)).toFixed(5) : 
                                  userdetail && userdetail.currentLevel && userdetail.currentLevel<4 ? userdetail && userdetail.levelprice5 : 0  } <span>BNB</span>
                              </h5>
                            </div>
                          </div>
                          <div className="buy_item_inner_right">
                            <div class="progress progress-bar-vertical progress_custom">
                              <div
                                class="progress-bar"
                                role="progressbar"
                                style={{ height: `${ userdetail && userdetail.currentLevel && userdetail.currentLevel==4 ? ((userdetail.upgradeAmount)*100)/userdetail.levelprice5 :
                                  userdetail && userdetail.currentLevel && userdetail.currentLevel<4 ? 0 : 100}%` }}
                                aria-valuenow={ userdetail && userdetail.currentLevel && userdetail.currentLevel==4 ? ((userdetail.upgradeAmount)*100)/userdetail.levelprice5 :
                                  userdetail && userdetail.currentLevel && userdetail.currentLevel<4 ? 0 : 100}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                { userdetail && userdetail.currentLevel && userdetail.currentLevel==4 ? (((userdetail.upgradeAmount)*100)/userdetail.levelprice5).toFixed(0) :
                                  userdetail && userdetail.currentLevel && userdetail.currentLevel<4 ? 0 : 100}%
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={userdetail && userdetail.currentLevel && userdetail.currentLevel>=5 ? "buy_item" : "buy_item buy_item_disabled"}>
                          <div className="buy_item_inner_left">
                            <div>
                              <h4>Level 6</h4>
                              <h2>
                                {userdetail && userdetail.levelprice6} <span>BNB</span>
                              </h2>
                            </div>
                            <div>
                              <h4>Remaining BNB</h4>
                              <h5>
                                { userdetail && userdetail.currentLevel && userdetail.currentLevel==5 ? (parseFloat(userdetail && userdetail.levelprice6) - parseFloat(userdetail.upgradeAmount)).toFixed(5) : 
                                  userdetail && userdetail.currentLevel && userdetail.currentLevel<5 ? userdetail && userdetail.levelprice6 : 0  } <span>BNB</span>
                              </h5>
                            </div>
                          </div>
                          <div className="buy_item_inner_right">
                            <div class="progress progress-bar-vertical progress_custom">
                              <div
                                class="progress-bar"
                                role="progressbar"
                                style={{ height: `${ userdetail && userdetail.currentLevel && userdetail.currentLevel==5 ? ((userdetail.upgradeAmount)*100)/userdetail.levelprice6 :
                                  userdetail && userdetail.currentLevel && userdetail.currentLevel<5 ? 0 : 100}%` }}
                                aria-valuenow={ userdetail && userdetail.currentLevel && userdetail.currentLevel==5 ? ((userdetail.upgradeAmount)*100)/userdetail.levelprice6 :
                                  userdetail && userdetail.currentLevel && userdetail.currentLevel<5 ? 0 : 100}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                { userdetail && userdetail.currentLevel && userdetail.currentLevel==5 ? (((userdetail.upgradeAmount)*100)/userdetail.levelprice6).toFixed(0) :
                                  userdetail && userdetail.currentLevel && userdetail.currentLevel<5 ? 0 : 100}%
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={userdetail && userdetail.currentLevel && userdetail.currentLevel>=6 ? "buy_item" : "buy_item buy_item_disabled"}>
                          <div className="buy_item_inner_left">
                            <div>
                              <h4>Level 7</h4>
                              <h2>
                                {userdetail && userdetail.levelprice7} <span>BNB</span>
                              </h2>
                            </div>
                            <div>
                              <h4>Remaining BNB</h4>
                              <h5>
                                { userdetail && userdetail.currentLevel && userdetail.currentLevel==6 ? (parseFloat(userdetail && userdetail.levelprice7) - parseFloat(userdetail.upgradeAmount)).toFixed(5) : 
                                  userdetail && userdetail.currentLevel && userdetail.currentLevel<6 ? userdetail && userdetail.levelprice7 : 0  } <span>BNB</span>
                              </h5>
                            </div>
                          </div>
                          <div className="buy_item_inner_right">
                            <div class="progress progress-bar-vertical progress_custom">
                              <div
                                class="progress-bar"
                                role="progressbar"
                                style={{ height: `${ userdetail && userdetail.currentLevel && userdetail.currentLevel==6 ? ((userdetail.upgradeAmount)*100)/userdetail.levelprice7 :
                                  userdetail && userdetail.currentLevel && userdetail.currentLevel<6 ? 0 : 100}%` }}
                                aria-valuenow={ userdetail && userdetail.currentLevel && userdetail.currentLevel==6 ? ((userdetail.upgradeAmount)*100)/userdetail.levelprice7 :
                                  userdetail && userdetail.currentLevel && userdetail.currentLevel<6 ? 0 : 100}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                { userdetail && userdetail.currentLevel && userdetail.currentLevel==6 ? (((userdetail.upgradeAmount)*100)/userdetail.levelprice7).toFixed(0) :
                                  userdetail && userdetail.currentLevel && userdetail.currentLevel<6 ? 0 : 100}%
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={userdetail && userdetail.currentLevel && userdetail.currentLevel>=7 ? "buy_item" : "buy_item buy_item_disabled"}>
                          <div className="buy_item_inner_left">
                            <div>
                              <h4>Level 8</h4>
                              <h2>
                                {userdetail && userdetail.levelprice8} <span>BNB</span>
                              </h2>
                            </div>
                            <div>
                              <h4>Remaining BNB</h4>
                              <h5>
                                { userdetail && userdetail.currentLevel && userdetail.currentLevel==7 ? (parseFloat(userdetail && userdetail.levelprice8) - parseFloat(userdetail.upgradeAmount)).toFixed(5) : 
                                  userdetail && userdetail.currentLevel && userdetail.currentLevel<7 ? userdetail && userdetail.levelprice8 : 0  } <span>BNB</span>
                              </h5>
                            </div>
                          </div>
                          <div className="buy_item_inner_right">
                            <div class="progress progress-bar-vertical progress_custom">
                              <div
                                class="progress-bar"
                                role="progressbar"
                                style={{ height: `${ userdetail && userdetail.currentLevel && userdetail.currentLevel==7 ? ((userdetail.upgradeAmount)*100)/userdetail.levelprice8:
                                  userdetail && userdetail.currentLevel && userdetail.currentLevel<7 ? 0 : 100}%` }}
                                aria-valuenow={ userdetail && userdetail.currentLevel && userdetail.currentLevel==7 ? ((userdetail.upgradeAmount)*100)/userdetail.levelprice8:
                                  userdetail && userdetail.currentLevel && userdetail.currentLevel<7 ? 0 : 100}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                { userdetail && userdetail.currentLevel && userdetail.currentLevel==7 ? (((userdetail.upgradeAmount)*100)/userdetail.levelprice8).toFixed(0) :
                                  userdetail && userdetail.currentLevel && userdetail.currentLevel<7 ? 0 : 100}%
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      </div>
                       
                      
                    </div>
                  </div>

                  <div className="primary_box dash_box mt-4">
                    <div className="dashbox_middle_bottom">
                      <h2 class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo">Generation Income</h2>
                      <div className="plan_income_table_flex_div accordion-collapse collapse" id="collapseTwo">
                        <div className="plan_income_table_div">
                          <div className="table-responsive">
                            <table className="table plan_income_table mt-0">
                              <thead>
                                <tr>
                                  <th>Level</th>
                                  <th>Downline</th>
                                  <th>Income</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>1</td>
                                  <td>{level1counts}</td>
                                  <td>{level1counts && profileinfo && profileinfo.generationincome && profileinfo.generationincome[0]?parseFloat(profileinfo.generationincome[0]).toFixed(6) : 0}</td>
                                </tr>
                                <tr>
                                  <td>2</td>
                                  <td>{level2counts}</td>
                                  <td>{level2counts && profileinfo && profileinfo.generationincome && profileinfo.generationincome[1]?parseFloat(profileinfo.generationincome[1]).toFixed(6) : 0}</td>
                                </tr>
                                <tr>
                                  <td>3</td>
                                  <td>{level3counts}</td>
                                  <td>{level3counts && profileinfo && profileinfo.generationincome && profileinfo.generationincome[2]?parseFloat(profileinfo.generationincome[2]).toFixed(6) : 0}</td>
                                </tr>
                                <tr>
                                  <td>4</td>
                                  <td>{level4counts}</td>
                                  <td>{level4counts && profileinfo && profileinfo.generationincome && profileinfo.generationincome[3]?parseFloat(profileinfo.generationincome[3]).toFixed(6) : 0}</td>
                                </tr>
                                <tr>
                                  <td>5</td>
                                  <td>{level5counts}</td>
                                  <td>{level5counts && profileinfo && profileinfo.generationincome && profileinfo.generationincome[4]?parseFloat(profileinfo.generationincome[4]).toFixed(6) : 0}</td>
                                </tr>
                                <tr>
                                  <td>6</td>
                                  <td>{level6counts}</td>
                                  <td>{level6counts && profileinfo && profileinfo.generationincome && profileinfo.generationincome[5]?parseFloat(profileinfo.generationincome[5]).toFixed(6) : 0}</td>
                                </tr>
                                <tr>
                                  <td>7</td>
                                  <td>{level7counts}</td>
                                  <td>{level7counts && profileinfo && profileinfo.generationincome && profileinfo.generationincome[6]?parseFloat(profileinfo.generationincome[6]).toFixed(6) : 0}</td>
                                </tr>
                                <tr>
                                  <td>8</td>
                                  <td>{level8counts}</td>
                                  <td>{level8counts && profileinfo && profileinfo.generationincome && profileinfo.generationincome[7]?parseFloat(profileinfo.generationincome[7]).toFixed(6) : 0}</td>
                                </tr>
                                <tr>
                                  <td>9</td>
                                  <td>{level9counts}</td>
                                  <td>{level9counts && profileinfo && profileinfo.generationincome && profileinfo.generationincome[8]?parseFloat(profileinfo.generationincome[8]).toFixed(6) : 0}</td>
                                </tr>
                                <tr>
                                  <td>10</td>
                                  <td>{level10counts}</td>
                                  <td>{level10counts && profileinfo && profileinfo.generationincome && profileinfo.generationincome[9]?parseFloat(profileinfo.generationincome[9]).toFixed(6) : 0}</td>
                                </tr>
                                <tr>
                                  <td>11</td>
                                  <td>{level11counts}</td>
                                  <td>{level11counts && profileinfo && profileinfo.generationincome && profileinfo.generationincome[10]?parseFloat(profileinfo.generationincome[10]).toFixed(6) : 0}</td>
                                </tr>
                                <tr>
                                  <td>12</td>
                                  <td>{level12counts}</td>
                                  <td>{level12counts && profileinfo && profileinfo.generationincome && profileinfo.generationincome[11]?parseFloat(profileinfo.generationincome[11]).toFixed(6) : 0}</td>
                                </tr>
                                <tr>
                                  <td>13</td>
                                  <td>{level13counts}</td>
                                  <td>{level13counts && profileinfo && profileinfo.generationincome && profileinfo.generationincome[12]?parseFloat(profileinfo.generationincome[12]).toFixed(6) : 0}</td>
                                </tr>
                                <tr>
                                  <td>14</td>
                                  <td>{level14counts}</td>
                                  <td>{level14counts && profileinfo && profileinfo.generationincome && profileinfo.generationincome[13]?parseFloat(profileinfo.generationincome[13]).toFixed(6) : 0}</td>
                                </tr>
                                <tr>
                                  <td>15</td>
                                  <td>{level15counts}</td>
                                  <td>{level15counts && profileinfo && profileinfo.generationincome && profileinfo.generationincome[14]?parseFloat(profileinfo.generationincome[14]).toFixed(6) : 0}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="plan_income_table_div">
                          <div className="table-responsive">
                            <table className="table plan_income_table mt-0">
                              <thead>
                                <tr>
                                  <th>Level</th>
                                  <th>Downline</th>
                                  <th>Income</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>16</td>
                                  <td>{level16counts}</td>
                                  <td>{level16counts && profileinfo && profileinfo.generationincome && profileinfo.generationincome[15]?parseFloat(profileinfo.generationincome[15]).toFixed(6) : 0}</td>
                                </tr>
                                <tr>
                                  <td>17</td>
                                  <td>{level17counts}</td>
                                  <td>{level17counts && profileinfo && profileinfo.generationincome && profileinfo.generationincome[16]?parseFloat(profileinfo.generationincome[16]).toFixed(6) : 0}</td>
                                </tr>
                                <tr>
                                  <td>18</td>
                                  <td>{level18counts}</td>
                                  <td>{level18counts && profileinfo && profileinfo.generationincome && profileinfo.generationincome[17]?parseFloat(profileinfo.generationincome[17]).toFixed(6) : 0}</td>
                                </tr>
                                <tr>
                                  <td>19</td>
                                  <td>{level19counts}</td>
                                  <td>{level19counts && profileinfo && profileinfo.generationincome && profileinfo.generationincome[18]?parseFloat(profileinfo.generationincome[18]).toFixed(6) : 0}</td>
                                </tr>
                                <tr>
                                  <td>20</td>
                                  <td>{level20counts}</td>
                                  <td>{level20counts && profileinfo && profileinfo.generationincome && profileinfo.generationincome[19]?parseFloat(profileinfo.generationincome[19]).toFixed(6) : 0}</td>
                                </tr>
                                <tr>
                                  <td>21</td>
                                  <td>{level21counts}</td>
                                  <td>{level21counts && profileinfo && profileinfo.generationincome && profileinfo.generationincome[20]?parseFloat(profileinfo.generationincome[20]).toFixed(6) : 0}</td>
                                </tr>
                                <tr>
                                  <td>22</td>
                                  <td>{level22counts}</td>
                                  <td>{level22counts && profileinfo && profileinfo.generationincome && profileinfo.generationincome[21]?parseFloat(profileinfo.generationincome[21]).toFixed(6) : 0}</td>
                                </tr>
                                <tr>
                                  <td>23</td>
                                  <td>{level23counts}</td>
                                  <td>{level23counts && profileinfo && profileinfo.generationincome && profileinfo.generationincome[22]?parseFloat(profileinfo.generationincome[22]).toFixed(6) : 0}</td>
                                </tr>
                                <tr>
                                  <td>24</td>
                                  <td>{level24counts}</td>
                                  <td>{level24counts && profileinfo && profileinfo.generationincome && profileinfo.generationincome[23]?parseFloat(profileinfo.generationincome[23]).toFixed(6) : 0}</td>
                                </tr>
                                <tr>
                                  <td>25</td>
                                  <td>{level25counts}</td>
                                  <td>{level25counts && profileinfo && profileinfo.generationincome && profileinfo.generationincome[24]?parseFloat(profileinfo.generationincome[24]).toFixed(6) : 0}</td>
                                </tr>
                                <tr>
                                  <td>26</td>
                                  <td>{level26counts}</td>
                                  <td>{level26counts && profileinfo && profileinfo.generationincome && profileinfo.generationincome[25]?parseFloat(profileinfo.generationincome[25]).toFixed(6) : 0}</td>
                                </tr>
                                <tr>
                                  <td>27</td>
                                  <td>{level27counts}</td>
                                  <td>{level27counts && profileinfo && profileinfo.generationincome && profileinfo.generationincome[26]?parseFloat(profileinfo.generationincome[26]).toFixed(6) : 0}</td>
                                </tr>
                                <tr>
                                  <td>28</td>
                                  <td>{level28counts}</td>
                                  <td>{level28counts && profileinfo && profileinfo.generationincome && profileinfo.generationincome[27]?parseFloat(profileinfo.generationincome[27]).toFixed(6) : 0}</td>
                                </tr>
                                <tr>
                                  <td>29</td>
                                  <td>{level29counts}</td>
                                  <td>{level29counts && profileinfo && profileinfo.generationincome && profileinfo.generationincome[28]?parseFloat(profileinfo.generationincome[28]).toFixed(6) : 0}</td>
                                </tr>
                                <tr>
                                  <td>30</td>
                                  <td>{level30counts}</td>
                                  <td>{level30counts && profileinfo && profileinfo.generationincome && profileinfo.generationincome[29]?parseFloat(profileinfo.generationincome[29]).toFixed(6) : 0}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                   <div className="primary_box dash_box mt-4">
                    <div className="dashbox_middle_bottom">
                      <h2 class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFive">Your Network</h2>
                      <div className="plan_income_table_div accordion-collapse collapse mt-4" id="collapseFive">
                        <div className="table-responsive">
                          <table className="table plan_income_table mt-0">
                            <thead>
                              <tr>
                                <th>Directs</th>
                                <th>User Id</th>
                                <th>Team Count</th>
                              </tr>
                            </thead>
                            <tbody>
                              {mynetwork &&
                                mynetwork.length > 0 &&
                                mynetwork.map((item, index) => {
                                  console.log(item, "======mynetwork");
                                  return (
                                    <tr>
                                      <td>{parseInt(index) + 1}</td>
                                      <td>{"ID " + item.userid}</td>
                                      <td>{arraylegcount[index]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="primary_box dash_box mt-4">
                    <div className="dashbox_middle_bottom">
                      <h2 class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree">Genealogy Tree</h2>
                      {/* <div className="inp_grp search_div">
                      <input
                        type="text"
                        className="form-control primary_inp"
                        placeholder="Search by id"
                      />
                      <button>Search</button>
                    </div> */}

                      <div className="genealogy_tree accordion-collapse collapse" id="collapseThree">
                        {!treeprogress ? (
                          <div>
                            <AnimatedTree
                              data={selected}
                              height={600}
                              width={800}
                              gProps={{
                                className: "node",
                                onClick: handleClick,
                              }}
                              nodeShape="image"
                              nodeProps={{
                                href: require("../assets/images/tree_icon.png"),
                                width: "22",
                                height: "24",
                              }}
                              margins={{
                                top: 50,
                                bottom: 50,
                                left: 80,
                                right: 80,
                              }}
                              textProps={{ dx: -16, dy: 25 }}
                            />
                          </div>
                        ) : (
                          <div>
                            <center>
                              <p className="text-danger mb-1">
                                Processing....,Please Wait
                              </p>
                            </center>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="primary_box dash_box mt-4">
                    <div className="dashbox_middle_bottom">
                      <h2 class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour">Withdraw History</h2>
                      <div className="plan_income_table_div accordion-collapse collapse mt-4" id="collapseFour">
                        <div className="table-responsive">
                          <table className="table plan_income_table mt-0">
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Date</th>
                                <th>Address</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                            {historylist && historylist.length>0 && historylist.map((item,index)=>{
                              return(
                                  <tr>
                                    <td>{parseInt(index)+1}</td>
                                    <td>{moment.unix(item.date).format("MM/DD/YYYY")}</td>
                                    <td>{item.address}</td>
                                    <td>{(parseFloat(item.amount)/1e18).toFixed(4)}</td>
                                  </tr>
                                )
                            })}                           
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div
        className="modal fade primary_modal"
        id="wallet_modal"
        tabIndex={-1}
        aria-labelledby="walletModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="walletModalLabel">
                Deposit
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <label for="exampleFormControlInput1" class="form-label">
                Invest Amount
              </label>
              <input
                type="Number"
                class="form-control primary_inp"
                id="exampleFormControlInput1"
                placeholder="Enter Invest Amount"
                value={depositamt}
                onChange={depositamtchange}
                min="0"
                max="160"
              />
              <div className="mt-3">
                <button
                  className="primary_btn"
                  type="button"
                  onClick={depositLoad == false ? deposit_bnb : () => empty()}
                >
                  {depositLoad == false
                    ? "Submit"
                    : "Processing..Please Wait.."}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
