import React, { useEffect , useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import CountUp from "react-countup";
import ScrollAnimation from "react-animate-on-scroll";
// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Web3 from "web3";
import { type WalletClient, useWalletClient } from 'wagmi';
import { useSelector } from "react-redux";
import config from "../config/config";
import { connection } from "../helper/connection.js";
import ZolttanABI from '../ABI/ZolttanABI.json'

// Scroll to Top
function ScrollToTopOnMount() {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  return null;
}

export default function Home(props) {
  var faq_settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,          
        },
      },
    ],
  };

  let chainid = config.NetworkId ;
  const { data: walletClient } = useWalletClient({ chainid })
  const walletConnection = useSelector((state) => state.walletConnection);
  const [address,setaddress] = useState("");
  const [totaluser,settotaluser] = useState(0);
  const [last24hruser,setlast24hruser] = useState(0);
  const [totalout,settotalout] = useState(0);

  useEffect(() => {
    getconnection();
    getrecords();
  }, [walletConnection, walletClient])

  async function getconnection(){
    var get = await connection();
    if (get && get.web3) {
      let web3 = new Web3(get.web3);
      let address = get.address;
      setaddress(address);
    }
  }

  async function getrecords(){
    try{
      let web3 = new Web3(config.rpcUrl);
      let zolttanContract = new web3.eth.Contract(ZolttanABI, config.ZolttanAddress);
      let total_user = await zolttanContract.methods.totalUsers().call();
      let last_24hruser = await zolttanContract.methods.getUsersJoinedLast24Hours().call();
      let total_out = await zolttanContract.methods.totalPayout().call();
      settotalout(total_out);
      setlast24hruser(last_24hruser);
      settotaluser(total_user);
    }catch(err){
      console.log(err,"====errrr")
    }
  }

  console.log(address,"===addressaddressaddress")

  return (
    <div>
      <ScrollToTopOnMount />
      <div className="bgimg" >
      <Navbar />
      <div className="page_header">
        <div className="container">
          <div className="page_header_content">
           
            <h6>Web3 Crypto Yielding Platform</h6>
            <h1>ZOLTTAN</h1>
            <h6>A WEB3 COMMUNITY</h6>
            {address && address != null && address!=undefined && address!="" ? "" : 
              <button className="primary_btn" data-bs-toggle="modal" data-bs-target="#connect_wallet_modal" >Connect Wallet</button>
            }
          </div>
        </div>
        {/* <div class="shootingStarContainer">
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
        </div>
        <div class="shootingStarContainer">
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
        </div>
        <div class="shootingStarContainer">
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
        </div>
        <div class="stars"></div>
        <div class="clouds"></div> */}
      </div>
      </div>
      <div className="main_wrapper">

        <section className="detailbox">
         <div className="container">
            <div className="row">
              <div className="col-md-4">
              <ScrollAnimation animateIn="slideInUp">
                <div className="box">
                  <p>All Participants</p>
                  <h5>{totaluser}</h5>
                </div>
                </ScrollAnimation>
              </div>
              <div className="col-md-4">
                <ScrollAnimation animateIn="slideInUp">
                <div className="box">
                  <p>Joined in 24 hours</p>
                  <h5>{last24hruser}</h5>
                </div>
                </ScrollAnimation>
              </div>
              <div className="col-md-4">
                <ScrollAnimation animateIn="slideInUp">
                <div className="box">
                  <p>Total Claimed BNB</p>
                  <h5>{(parseFloat(totalout)/1e18).toFixed(5)}</h5>
                </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section>

        <section className="about">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <ScrollAnimation animateIn="slideInUp">
                <h4>What's</h4>
                   <img
                        src={require("../assets/images/zolttan.png")}
                        className="img-fluid"
                        alt="Icon"
                      />
                </ScrollAnimation>
                <ScrollAnimation animateIn="slideInUp">
                <p className="inner_para" >Community-driven crypto project, There is no central authority or entity managing it, rather it is collectively owned and managed by its members using BEP-20 smart Blockchain contract.</p>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section>


        <section className="about_box">
          <div className="container">
          <ScrollAnimation animateIn="slideInUp">
          <div className="about_boxbg">
            <div className="box">
            <div className="row">
                <div className="col-md-4 text-center">
                   <img
                        src={require("../assets/images/about.png")}
                        className="img-fluid"
                        alt="Icon"
                      />
                 </div>
                   <div className="col-md-8">
                    <h3>About ZOLTTAN</h3>
                     <p className="inner_para">Our passion is to bring innovating new technologies and creating sustainable programs that change lives. When you have the power to make a difference in the digital world and well-being of others, every day is extraordinary. We’re headed towards the future of digital technology, come join us</p>
                   </div>
            </div>
            </div>
            </div>
            </ScrollAnimation>
          </div>
        </section>


        <section className="whatsnew">
          <div className="container">
            <h2 className="h2tag" >What's New ZOLTTAN</h2>
            <div className="row">
              <div className="col-md-6 m-auto">
              <ScrollAnimation animateIn="slideInUp">
                <h3>Matrix Generation Income</h3>
                <p className="inner_para" >Matrix generation system is dedicated to help the ZOLTTAN global community members.Many businesses are aware of their importance, but it’s not just about ensuring updates are made that’s important. In ZOLTTAN matrix upgrades are managed effectively so that they don’t interrupt your workforce and your income. The ZOLTTAN matrix generation income system will drive automatically to achieve your income</p>
                <h3>Performance Overhaul</h3>
                <p className="inner_para" >Matrix Passive income does require work. However, much of that work is done at the start so you can enjoy the income later with less effort.</p>
                </ScrollAnimation>
              </div>
              <div className="col-md-6 m-auto">
              <ScrollAnimation animateIn="slideInUp">
                <div className="imgbox">
                 <img src={require("../assets/images/income.png")} className="img-fluid" alt="Icon" />
                 </div>
                 </ScrollAnimation>
              </div>
            </div>
          </div>
        </section>

        <section className="whatsnew whatsnew_flex">
          <div className="container">
            <div className="row">
              <div className="col-md-6 m-auto">
              <ScrollAnimation animateIn="slideInUp">
                <div className="imgbox">
                 <img src={require("../assets/images/importance.png")} className="img-fluid" alt="Icon" />
                 </div>
                 </ScrollAnimation>
              </div>
              <div className="col-md-6 m-auto">
              <ScrollAnimation animateIn="slideInUp">
                <h3>Importance Of Decentralized Community</h3>
                <p className="inner_para" >In the decentralized world of Web3, the community plays a crucial role in driving innovation, adoption, and governance. Decentralization is considered the most important aspect of Web3 technology since it allows for more open, transparent, and inclusive participation – without middlemen involved.</p>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section>

        
        <section className="whatsnew">
          <div className="container">
            <div className="row">
              <div className="col-md-6 m-auto">
                <ScrollAnimation animateIn="slideInUp">
                <h3>Why Smart Contract Referral Program Is Most Important In Today's Period?</h3>
                <p className="inner_para" >
                Referral rewards locked in a smart contract. With At trace, there is no more second guessing or wondering: The rewards budget is locked in the smart contract and paid to account holders and participants during the referral program. Guaranteed code is law and each on-chain action will be reflected in your wallet.
                </p>
                </ScrollAnimation>
              </div>
              <div className="col-md-6 m-auto">
                <ScrollAnimation animateIn="slideInUp">
                <div className="imgbox">
                 <img src={require("../assets/images/why.png")} className="img-fluid" alt="Icon" />
                 </div>
                 </ScrollAnimation>
              </div>
            </div>
          </div>
        </section>


        {/* Feature Section */}
        {/* <section className="features_section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-4">
                <ScrollAnimation animateIn="slideInUp">
                  <h3 className="main_title_small">International Community</h3>
                  <h2 className="main_title">Global Business</h2>
                </ScrollAnimation>
              </div>
              <div className="col-md-12 col-lg-6">
                <ScrollAnimation animateIn="slideInUp">
                  <p className="main_subtitle">
                    Zolttan marketing is powered by the revolutionary smart
                    contract technology*. The code for Zolttan smart contract can
                    be seen here fully open, so you can be completely confident
                    in the security and long-term operation of the project.
                  </p>
                </ScrollAnimation>
              </div>
            </div>
          </div>

          <div className="feature_row_setion">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-4">
                  <ScrollAnimation animateIn="slideInUp">
                    <div className="features_box">
                      <img
                        src={require("../assets/images/icon_01.png")}
                        className="img-fluid"
                        alt="Icon"
                      />
                      <h4>Zero Risk</h4>
                      <p className="inner_para">
                        100 % RISK Free plaform which offers 100 profit growth .{" "}
                      </p>
                    </div>
                  </ScrollAnimation>
                </div>
                <div className="col-md-6 col-lg-4">
                  <ScrollAnimation animateIn="slideInUp">
                    <div className="features_box">
                      <img
                        src={require("../assets/images/icon_02.png")}
                        className="img-fluid"
                        alt="Icon"
                      />
                      <h4>Transactions</h4>
                      <p className="inner_para">
                        Faster Transaction with secure environment  powered by BNB chain {" "}
                      </p>
                    </div>
                  </ScrollAnimation>
                </div>
                <div className="col-md-6 col-lg-4">
                  <ScrollAnimation animateIn="slideInUp">
                    <div className="features_box">
                      <img
                        src={require("../assets/images/icon_03.png")}
                        className="img-fluid"
                        alt="Icon"
                      />
                      <h4>100% Profits</h4>
                      <p className="inner_para">
                        New Oppertunity to get Financial Freedom in Crypto Industry{" "}
                      </p>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* Partners Income Section */}
        {/* <section className="income_section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <ScrollAnimation animateIn="slideInUp">
                  <h3 className="main_title_small text-center">
                    How it Works?
                  </h3>
                  <h2 className="main_title text-center">
                    Partners Income Explained
                  </h2>
                  <p className="main_subtitle text-center">
                    The parent or first user will enter the platform with an
                    entry amount of $50 When you refer new members to register
                    in Zolttan platform with your referral link, you will get 20%
                    of 50 USD = 10 USD for each newly registered member.
                  </p>
                </ScrollAnimation>
              </div>
            </div>
          </div>
          <div className="income_section_row">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <ScrollAnimation animateIn="slideInUp">
                    <div className="tree_struc">
                      <img
                        src={require("../assets/images/tree_structure.png")}
                        className="img-fluid"
                        alt="Icon"
                      />
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </section> */}

      {/* <section className="visitor_table">
          <div className="container">
          <ScrollAnimation animateIn="slideInUp">
            <h2 className="h2tag" >Online Visitors</h2>
          <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th><div>Rank</div></th> 
                            <th><div>Users</div></th>
                            <th><div>Profit</div></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr> 
                            <td><div> <img src={require("../assets/images/rank1.png")} className="img-fluid" alt="Icon" /></div></td>
                            <td><div>Felicitatem3</div></td>
                            <td><div>123456</div></td>
                          </tr>
                          <tr> 
                            <td><div> <img src={require("../assets/images/rank2.png")} className="img-fluid" alt="Icon" /></div></td>
                            <td><div>Downsomuch</div></td>
                            <td><div>123456</div></td>
                          </tr>
                          <tr>
                            <td><div> <img src={require("../assets/images/rank3.png")} className="img-fluid" alt="Icon" /></div></td>
                            <td><div>Quyet888</div></td>
                            <td><div>123456</div></td>
                          </tr>
                          <tr>
                            <td><div> <img src={require("../assets/images/rank4.png")} className="img-fluid" alt="Icon" /></div></td>
                            <td><div>Wolfjoy</div></td>
                            <td><div>123456</div></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    </ScrollAnimation>
            </div>
        </section>*/}


        {/* <section className="income_plan_section ">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-8 m-auto">
                <ScrollAnimation animateIn="slideInUp">
                  <h3 className="main_title_small text-center">Income Plan</h3>
                  <h2 className="main_title text-center">
                    Upgrade Matrix Income
                  </h2>
                </ScrollAnimation>
                <ScrollAnimation animateIn="slideInUp">
                  <div className="plan_income_table_div">
                    <div className="table-responsive">
                      <table className="table plan_income_table">
                        <thead>
                          <tr>
                            <th>Rank</th> 
                            <th>Users</th>
                            <th>Profit</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr> 
                            <td> <img src={require("../assets/images/why.png")} className="img-fluid" alt="Icon" /></td>
                            <td>Felicitatem3</td>
                            <td>123456</td>
                          </tr>
                          <tr> 
                            <td> <img src={require("../assets/images/why.png")} className="img-fluid" alt="Icon" /></td>
                            <td>Downsomuch</td>
                            <td>123456</td>
                          </tr>
                          <tr>
                            <td> <img src={require("../assets/images/why.png")} className="img-fluid" alt="Icon" /></td>
                            <td>Quyet888</td>
                            <td>123456</td>
                          </tr>
                          <tr>
                            <td> <img src={require("../assets/images/why.png")} className="img-fluid" alt="Icon" /></td>
                            <td>Wolfjoy</td>
                            <td>123456</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section> */}

        {/* faq Section */}
        <section className="faq_section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <ScrollAnimation animateIn="slideInUp">
                  <div className="head">
                  <h2 className="h2tag">Learn More ZOLTTAN</h2>
                  {/* <p className="inner_para" >If you still have questions or simply want to learn more you can check our <a> Help Docs.</a></p> */}
         
                  </div>
                </ScrollAnimation>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ScrollAnimation animateIn="slideInUp">
                  <div className="faq_carousel">
                    <Slider {...faq_settings}>
                      <div>
                        <div className="faq_box">
                          <div className="faq_content">
                            <h2>WHAT IS CRYPTO CURRENCY?</h2>
                            <p>
                          Crypto currency is digital money that doesn't require a bank or financial institution to verify transactions and can be used for purchases or as an investment. Transactions are then verified and recorded on a blockchain, an unchangeable ledger that tracks and records     </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="faq_box">
                          <div className="faq_content">
                            <h2>HOW BLOCKCHAIN WORKS</h2>
                            <p>
                          A blockchain is a decentralized, distributed and public digital ledger that is used to record transactions across many computers so that the record cannot be altered retroactively without the alteration of all subsequent blocks and the consensus of the network.     </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="faq_box">
                          <div className="faq_content">
                            <h2>WHY SMART CONTRACT REFERRAL PROGRAM IS MOST IMPORTANT IN TODAY'S PERIOD?</h2>
                            <p>
                         Referral rewards locked in a smart contract. With At trace, there is no more second guessing or wondering: The rewards budget is locked in the smart contract and paid to account holders and participants during the referral program. Guaranteed code is law and each on-chain action will be reflected in your wallet.        </p>
                          </div>
                        </div>
                      </div>
                      {/* <div>
                        <div className="faq_box">
                          <div className="faq_content">
                            <h2>Zoltan Joining Procedure</h2>
                            <ol>
                              <li>Download and install your new Trust Wallet, head to trustwallet.com and chose the Relevant download link for your device.</li>
                              <li>Once you have downloaded and installed the app, open Trust Wallet and tap on "Create a new wallet". In the next step you will receive your 12 word seed phrase, which acts as a backup for your funds. </li>
                              <li>Before you finish the step of creating the wallet, verify that you wrote down the 12 word phrase in the correct order. Do not take a screenshot; it's not safe to store your recovery phrase in your camera roll. It should be written down and stored in a safe place.</li>
                            </ol>
                          </div>
                        </div>
                      </div> */}
                      <div>
                        <div className="faq_box">
                          <div className="faq_content earningtypes">
                            <h2>Types of Earning</h2>
                            <h5>Get Paid 6 Types of Earning</h5>
                            <div className="flx">
                            <ul className="me-2">
                            <li>1.Direct Refferal Income</li> 
                            <li>2.Matrix Generation Income</li> 
                            <li>3.Matrix Passive Income</li> 
                            </ul>
                            <ul>
                            <li>4.Generation Income</li> 
                            <li>5.Team Advancement Income</li> 
                            <li>6.Royalty Achievement Income</li> 
                            </ul>
                            </div>
                          </div>
                        </div>
                      </div> 
                    </Slider>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}
