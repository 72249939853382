import React from 'react';
import config from '../config/config'
import { Link } from "react-router-dom";

export default function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <div className='footer_panel'>

          <div className='footer_panel_left'>

            <ul className='footer_menu_links'>
              {/* <li><Link to="/disclaimer">Disclaimer</Link></li> */}
              
              {/* <li><Link to="terms">Terms</Link></li> */}
            </ul>
            <p>Smart-contract address: </p>
            <a href={config.txUrlAddress + config.ZolttanAddress} target="_blank">{config.ZolttanAddress}</a>
            {/* <p>Token-contract address: </p>
            <a href={config.txUrlAddress + config.ZolttanTokenAddress} target="_blank">{config.ZolttanTokenAddress}</a> */}
            {/* <p>Copy right © {new Date().getFullYear()} <a href="#">Zolttan</a>, All rights Reserved</p> */}

          </div>
          <div className='footer_panel_right'>
            <ul className='footer_social_links'>
              <li><a href="https://www.facebook.com/profile.php?id=100094908362768" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
              <li><a href="https://t.me/zolttan" target="_blank"><i className="fab fa-telegram"></i></a></li>
              <li><a href="https://instagram.com/zolttanofficial?igshid=OGQ5ZDc2ODk2ZA==" target="_blank"><i class="fab fa-instagram instagram"></i></a></li>
              <li><a href="https://youtube.com/@ZolttanOfficial" target="_blank"><i class="fab fa-youtube"></i></a></li>
            </ul>
          </div>
        </div>
        <p>Copy right © {new Date().getFullYear()} <a href="#">Zolttan</a>, All rights Reserved</p>
      </div>
    </footer>
  );
}